import { GlobeIcon } from '@radix-ui/react-icons';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { SidebarNavigationTrigger } from 'components/main/sidebar/nav-trigger';
import env from 'config';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { MainService } from 'services/main.service';

const INTERVAL_SECONDS = env.identifier === 'local' ? 5 : 60;

// smaller value => more aggressive warnings
const MAX_MS_BEFORE_WARN = 500;
const MAX_SAMPLES = 3;

// we average the results of the most recent pings to determine if we're slow
const lastPings: number[] = [];

export const ConnectionWarning = () => {
  const [seconds, setSeconds] = useState(0);

  const [show, setShow] = useState(false);

  // once dismissed, the banner won't re-appear for the rest of the session
  // note: not currently used
  const [dismissed, setDismissed] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (dismissed) {
        clearInterval(interval);
        return;
      }

      setSeconds((seconds) => seconds + INTERVAL_SECONDS);
    }, INTERVAL_SECONDS * 1_000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // even if banner is showing, we will still check again in case the connection improves and we can hide
    const start = Date.now();

    MainService.getInstance()
      .ping()
      .finally(() => {
        const ping = Date.now() - start;

        if (lastPings.length >= MAX_SAMPLES) {
          // only keep the most recent pings
          lastPings.shift();
        }

        lastPings.push(ping);

        const mean = _.mean(lastPings);

        console.debug({
          ping: `${ping}ms`,
          mean: `${mean.toFixed(2)}ms`,
          samples: lastPings.length,
        });

        if (lastPings.length === 1) {
          // a single bad sample at startup needs to be extra slow to trigger the warning
          // reason: lots of startup requests could inflate the time for the first ping
          setShow(ping >= MAX_MS_BEFORE_WARN * 2);
          return;
        }

        // average the most recent pings to prevent outliers from flagging too easily
        setShow(mean >= MAX_MS_BEFORE_WARN);
      });
  }, [seconds]);

  const [dialog, setDialog] = useState<number>();

  if (!show) {
    return <></>;
  }

  return (
    <ErrorBoundary componentName="ConnectionWarning">
      <SidebarNavigationTrigger
        label="common.slow-connection-detected"
        icon={<GlobeIcon />}
        color={RADIX.COLOR.WARNING}
        onClick={() => setDialog(Date.now())}
        small
      />

      {dialog && (
        <CommonConfirmationDialog
          key={dialog}
          identifier="ConnectionWarningDescription"
          title="common.slow-connection-detected"
          content={<ReactMarkdown children={t('common.slow-connection-msg')} />}
          cancel={{
            label: 'common.dismiss',
            color: RADIX.COLOR.ACCENT,
          }}
          action={{
            invisible: true,
          }}
        />
      )}
    </ErrorBoundary>
  );
};
