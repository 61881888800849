import {
  MIN_MANUAL_TRAIN_SHOTS,
  MIN_QUICK_TRAIN_SHOTS,
} from 'classes/plate-canvas';
import { InstallStep } from 'enums/installation';
import { IInstallStepDef } from 'interfaces/i-installation';
import { InstallStepStatus, TrainingMode } from 'lib_ts/enums/machine.enums';
import { ReferenceListType } from 'lib_ts/enums/pitches.enums';
import { TrackingDevice } from 'lib_ts/enums/training.enums';
import { IInstallationDetails } from 'lib_ts/interfaces/i-machine';
import { IStrikeZone } from 'lib_ts/interfaces/i-strike-zone';
import { IPlateLoc } from 'lib_ts/interfaces/pitches';
import { IShotConfidence } from 'lib_ts/interfaces/training/i-machine-shot';

export class TrainingHelper {
  static minShotsForMode = (mode: TrainingMode): number => {
    switch (mode) {
      case TrainingMode.Manual: {
        return MIN_MANUAL_TRAIN_SHOTS;
      }

      case TrainingMode.Quick:
      default: {
        return MIN_QUICK_TRAIN_SHOTS;
      }
    }
  };

  static getDataCollectorShots(
    type: ReferenceListType,
    // aiming or analysis
    aiming: boolean
  ): number {
    switch (type) {
      case ReferenceListType.BallTypeCalibration: {
        return aiming ? 3 : 0;
      }

      case ReferenceListType.Repeatability: {
        return aiming ? 3 : 25;
      }

      default: {
        return 0;
      }
    }
  }

  /** by default returns results in seconds */
  static getETAForShots = (remaining: number, unit: 'sec' | 'min') => {
    const ESTIMATE_TIME_PER_SHOT_SEC = 15;
    const seconds = ESTIMATE_TIME_PER_SHOT_SEC * remaining;

    if (unit === 'min') {
      return Math.ceil(seconds / 60);
    }

    return seconds;
  };

  static isPlateInZone(config: {
    loc: IPlateLoc;
    zone: IStrikeZone;
    buffer: number;
  }): boolean {
    if (config.loc.plate_x > config.zone.right_ft + config.buffer) {
      // outside right
      return false;
    }

    if (config.loc.plate_x < config.zone.left_ft - config.buffer) {
      // outside left
      return false;
    }

    if (config.loc.plate_z > config.zone.top_ft + config.buffer) {
      // outside above
      return false;
    }

    if (config.loc.plate_z < config.zone.bottom_ft - config.buffer) {
      // outside below
      return false;
    }

    return true;
  }

  static isConfidenceSufficient(config: {
    device: TrackingDevice;
    confidence: IShotConfidence;
    minValue: number;
  }): boolean {
    if (
      config.device === TrackingDevice.RapsodoV3PRO &&
      config.confidence.PITCH_SpinConfidence < config.minValue
    ) {
      return false;
    }

    if (config.confidence.PITCH_SpeedConfidence < config.minValue) {
      return false;
    }

    if (
      config.confidence.PITCH_StrikePositionHeightConfidence < config.minValue
    ) {
      return false;
    }

    if (
      config.confidence.PITCH_StrikePositionSideConfidence < config.minValue
    ) {
      return false;
    }

    return true;
  }

  static getInstallationSteps(
    details: IInstallationDetails
  ): IInstallStepDef[] {
    return [
      {
        id: InstallStep.TestShot,
        label: 'Test Shot Firing',
        status: details.step_test_shot ?? InstallStepStatus.Incomplete,
      },
      {
        id: InstallStep.GeneralConfig,
        label: 'General Config',
        status: details.step_general_config ?? InstallStepStatus.Incomplete,
      },
      {
        id: InstallStep.ProjectorConfig,
        label: 'Projector Config',
        status: details.step_projector_config ?? InstallStepStatus.Incomplete,
      },
      {
        id: InstallStep.BallType,
        label: 'Ball Type Calibration',
        status: details.step_ball_type ?? InstallStepStatus.Incomplete,
      },
      {
        id: InstallStep.Repeatability,
        label: 'Repeatability Testing',
        status: details.step_repeatability ?? InstallStepStatus.Incomplete,
      },
      {
        id: InstallStep.Results,
        label: 'Results',
        status: details.step_results ?? InstallStepStatus.Incomplete,
      },
      {
        id: InstallStep.End,
        label: '--hidden--',
        status: InstallStepStatus.Incomplete,
      },
    ];
  }
}
