import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { Box, Flex, Grid, Text } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonNavMenu } from 'components/common/layout/nav-menu';
import { SectionContent } from 'components/common/settings-dialog/content';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext } from 'contexts/machine.context';
import {
  ALL_SECTIONS,
  MachineSettingsTabKey,
  SettingsSection,
  UserSettingsTabKey,
} from 'enums/settings';
import { t } from 'i18next';
import { IBaseDialog, IFullDialog } from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useState } from 'react';

const COMPONENT_NAME = 'SettingsDialog';

export interface ISettingsDialog extends IBaseDialog {
  initialSection: SettingsSection;
  /** if provided, only tabs listed here will be rendered, length should be at least 1 if provided */
  sections?: SettingsSection[];

  initialMachineTab?: MachineSettingsTabKey;
  initialUserTab?: UserSettingsTabKey;
}

export const SettingsDialog = (props: ISettingsDialog) => {
  const machineCx = useContext(MachineContext);
  const authCx = useContext(AuthContext);

  const [sections] = useState(
    ALL_SECTIONS.filter(
      (s) => !props.sections || props.sections.includes(s.value)
    )
  );

  const [active, setActive] = useState(props.initialSection);

  const dialogProps: IFullDialog = {
    identifier: props.identifier,
    title: 'common.settings',
    width: RADIX.DIALOG.WIDTH.XL,
    vFlexHeight: RADIX.DIALOG.HEIGHT.LG,
    loading: machineCx.loading || authCx.loading,
    onClose: props.onClose,
    content: (
      <Grid columns="4" className={RADIX.VFLEX.WRAPPER} gap={RADIX.FLEX.GAP.MD}>
        <Box>
          <CommonNavMenu orientation="vertical">
            {sections.map((s, i) => (
              <NavigationMenu.Item key={i}>
                <NavigationMenu.Trigger
                  className={StringHelper.classNames([
                    'NavigationMenuTrigger',
                    active === s.value ? 'active' : 'inactive',
                  ])}
                  onClick={() => setActive(s.value)}
                >
                  <Box pt="2" pb="2" pl="3" pr="3">
                    <Text
                      size="2"
                      color={
                        active === s.value ? RADIX.COLOR.ACCENT : undefined
                      }
                    >
                      {t(s.label)}
                    </Text>
                  </Box>
                </NavigationMenu.Trigger>
              </NavigationMenu.Item>
            ))}
          </CommonNavMenu>
        </Box>
        <Flex
          gridColumn="span 3"
          overflowX="hidden"
          overflowY="auto"
          className={RADIX.VFLEX.COLUMN}
          gap={RADIX.FLEX.GAP.MD}
          pr="2"
        >
          <Box className={RADIX.VFLEX.GROW}>
            <SectionContent {...props} active={active} />
          </Box>
        </Flex>
      </Grid>
    ),
  };

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog {...dialogProps} />
    </ErrorBoundary>
  );
};
