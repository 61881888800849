import { AnalyticsRouter } from 'components/sections/analytics/router';
import useAnalyticsStore from 'components/sections/analytics/store/use-analytics-store';
import { MachineContext } from 'contexts/machine.context';
import { useContext, useEffect } from 'react';

export const Analytics = () => {
  const {
    machine: { machineID },
  } = useContext(MachineContext);

  // Loading all machineSessions upon mounting bc we need them for both hitting reports and hitter session
  // TODO: getMachineSessionByID query that that returns a specific session summary for a hitter session

  const { fetchMachineSessions } = useAnalyticsStore();

  useEffect(() => {
    if (!machineID) {
      return;
    }

    fetchMachineSessions({
      machineID: machineID,
    });
  }, [fetchMachineSessions, machineID]);

  return <AnalyticsRouter />;
};
