import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PresetTrainingDialogHoC } from 'components/machine/dialogs/preset-training';
import { TrainingDialogHoC } from 'components/machine/dialogs/training';
import { ResetTrainingDialog } from 'components/sections/pitch-list/dialogs';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { SectionsContext } from 'contexts/sections.context';
import { isPast, lightFormat, parseISO } from 'date-fns';
import { SectionName } from 'enums/route.enums';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import {
  PitcherHand,
  PitcherRelease,
  PlayerLevel,
} from 'lib_ts/enums/pitches.enums';
import { IExpiringUrlDict } from 'lib_ts/interfaces/common/i-url-dict';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { IPitchList } from 'lib_ts/interfaces/pitches/i-pitch-list';
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MainService } from 'services/main.service';
import { PitchListsService } from 'services/pitch-lists.service';
import { PitchesService } from 'services/pitches.service';

const CONTEXT_NAME = 'QuickSessionContext';

interface IOptionsDict {
  names: string[];
  _created: string[];
}

export enum QuickSessionStep {
  SelectCard,
  SelectPitch,
}

interface ICardFilters {
  names: string[];
  key: number;

  release?: PitcherRelease;
  level?: PlayerLevel;
  hand?: PitcherHand;
}

export interface IQuickSessionContext {
  loading: boolean;

  options: IOptionsDict;

  step: QuickSessionStep;

  cards: IPitchList[];
  filteredCards: IPitchList[];
  cardsLoaded: boolean;

  avatarURLs: IExpiringUrlDict;
  active?: IPitchList;
  activePitches: IPitch[];

  cardFilters: ICardFilters;
  readonly setCardFilters: (value: Partial<ICardFilters>) => void;

  readonly setStep: (step: QuickSessionStep) => void;
  readonly selectCard: (card_id: string) => Promise<void>;

  readonly resetPitches: (pitches: IPitch[]) => void;

  training: boolean;
  readonly trainPitches: (pitches: IPitch[]) => void;

  tags: string;
  readonly setTags: (value: string) => void;
}

const DEFAULT: IQuickSessionContext = {
  step: QuickSessionStep.SelectCard,

  options: {
    names: [],
    _created: [],
  },

  cards: [],
  filteredCards: [],
  cardsLoaded: false,

  avatarURLs: {},
  activePitches: [],
  loading: false,

  cardFilters: {
    names: [],
    key: 0,
  },
  setCardFilters: () => console.error(`${CONTEXT_NAME}: not init`),

  setStep: () => console.error(`${CONTEXT_NAME}: not init`),
  selectCard: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  resetPitches: () => console.error(`${CONTEXT_NAME}: not init`),

  training: false,
  trainPitches: () => console.error(`${CONTEXT_NAME}: not init`),

  tags: '',
  setTags: () => console.error(`${CONTEXT_NAME}: not init`),
};

const getOptions = (data: IPitchList[]): IOptionsDict => {
  if (data) {
    return {
      names: ArrayHelper.unique(data.map((m) => m.card?.name ?? '')),

      _created: ArrayHelper.unique(
        data.map((m) =>
          m._created ? lightFormat(parseISO(m._created), 'yyyy-MM-dd') : ''
        )
      ),
    };
  } else {
    return DEFAULT.options;
  }
};

export const QuickSessionContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const QuickSessionProvider: FC<IProps> = (props) => {
  const { current, effectiveTrainingMode } = useContext(AuthContext);
  const { active, tryGoHome } = useContext(SectionsContext);

  const machineCx = useContext(MachineContext);
  const { machine } = useContext(MachineContext);

  const { aggReady, updatePitches } = useContext(MatchingShotsContext);

  const [_loading, _setLoading] = useState(DEFAULT.loading);

  const [_step, _setStep] = useState(DEFAULT.step);

  const [_cards, _setCards] = useState<IPitchList[]>([]);

  const [_cardFilters, _setCardFilters] = useState(DEFAULT.cardFilters);

  const _filteredCards = useMemo(() => {
    const output = _cards
      .filter(
        (c) =>
          _cardFilters.names.length === 0 ||
          _cardFilters.names.includes(c.card?.name ?? '')
      )
      .filter(
        (c) => !_cardFilters.level || c.card?.level === _cardFilters.level
      )
      .filter((c) => !_cardFilters.hand || c.card?.hand === _cardFilters.hand)
      .filter(
        (c) => !_cardFilters.release || c.card?.release === _cardFilters.release
      );

    return output;
  }, [_cards, _cardFilters]);

  const [_cardsLoaded, _setCardsLoaded] = useState(DEFAULT.cardsLoaded);

  const [_avatarURLs, _setAvatarURLs] = useState(DEFAULT.avatarURLs);
  const [_active, _setActive] = useState(DEFAULT.active);

  const [_activePitches, _setActivePitches] = useState(DEFAULT.activePitches);

  // automatically load shots to enable firing trained pitches
  useEffect(() => {
    if (_activePitches.length === 0) {
      return;
    }

    updatePitches({
      pitches: _activePitches,
      includeHitterPresent: false,
      includeLowConfidence: true,
    });
  }, [_activePitches]);

  const _options = useMemo(() => getOptions(_cards), [_cards]);

  const _changeActive = useCallback(
    async (config: { trigger: string; list_id?: string }) => {
      try {
        const nextActive = _cards.find((l) => l._id === config.list_id);

        if (config.list_id && !nextActive && _cards.length > 0) {
          NotifyHelper.warning({
            message_md: `You do not have access to player card \`${config.list_id}\`.`,
          });

          tryGoHome();
        }

        if (!nextActive) {
          return;
        }

        _setActive(nextActive);

        _setStep(QuickSessionStep.SelectPitch);

        _setLoading(true);

        const pitches = await PitchesService.getInstance().getListPitches(
          nextActive._id
        );

        _setActivePitches(pitches);

        _setLoading(false);
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            'There was an error while preparing your player card. Please try again.',
        });
        _setLoading(false);
      }
    },
    [_cards]
  );

  const [managePitches, setManagePitches] = useState<IPitch[]>([]);
  const [dialogReset, setDialogReset] = useState<number | undefined>();
  const [dialogTraining, setDialogTraining] = useState<number | undefined>();

  // for labelling fires, if enabled via env
  const [_tags, _setTags] = useState('');

  const state: IQuickSessionContext = {
    loading: _loading,

    step: _step,

    cards: _cards,
    cardsLoaded: _cardsLoaded,
    filteredCards: _filteredCards,

    avatarURLs: _avatarURLs,
    active: _active,
    activePitches: _activePitches,
    options: _options,

    cardFilters: _cardFilters,
    setCardFilters: (v) =>
      _setCardFilters({
        ..._cardFilters,
        ...v,
      }),

    setStep: (step) => _setStep(step),

    selectCard: (card_id) =>
      _changeActive({
        trigger: 'user selected player card',
        list_id: card_id,
      }),

    resetPitches: (pitches) => {
      setManagePitches(pitches);
      setDialogReset(Date.now());
    },

    training: dialogTraining !== undefined,
    trainPitches: (pitches) => {
      setManagePitches(pitches);
      setDialogTraining(Date.now());
    },

    tags: _tags,
    setTags: _setTags,
  };

  const _loadData = async () => {
    try {
      _setLoading(true);

      const cards = await PitchListsService.getInstance()
        .getCards()
        .then((r) => {
          return r;
        })
        .catch((e) => {
          console.error(e);
          return [];
        });

      _setCards(cards);

      // toggles on the cards access callout if there are no cards found
      _setCardsLoaded(true);

      // update avatarURLs
      const noAvatars = cards
        .filter((c) => {
          if (!c.card?.avatar_path) {
            // card doesn't have an avatar
            return false;
          }

          // card's avatar doesn't have a signed URL (e.g. from previous load)
          const entry = _avatarURLs[c.card.avatar_path];
          return !entry || isPast(entry.expires);
        })
        .map((c) => c.card?.avatar_path ?? '');

      if (noAvatars.length > 0) {
        const dict = await MainService.getInstance().signUrls(
          ArrayHelper.unique(noAvatars)
        );

        _setAvatarURLs({
          ..._avatarURLs,
          ...dict,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      _setLoading(false);
    }
  };

  useEffect(() => {
    if (current.auth && current.quick_session) {
      _loadData();
      return;
    }

    _setCards([]);
  }, [
    /** anything that might change access to QS should trigger a reload */
    current.auth,
    current.quick_session,
    current.session,
    /** anything that might result in different pitch mss should also trigger a reload */
    machine.machineID,
    machine.ball_type,
  ]);

  useEffect(() => {
    if (!current.auth) {
      return;
    }

    if (!current.quick_session) {
      return;
    }

    if (active.section !== SectionName.QuickSession) {
      return;
    }

    if (!active.fragments) {
      return;
    }

    if (active.fragments.length === 0) {
      return;
    }

    if (_cards.length === 0) {
      return;
    }

    _changeActive({
      trigger: 'quick session context, detect sections fragment',
      list_id: active.fragments[0],
    });
  }, [
    _cards,
    current.auth,
    current.quick_session,
    active.section,
    active.fragments,
  ]);

  /** refresh the list (which will populate msDict if necessary) whenever machine changes */
  useEffect(() => {
    if (!_active) {
      return;
    }

    _changeActive({
      trigger: 'machine context changed',
      list_id: _active._id,
    });
  }, [machine.machineID, machine.ball_type]);

  const mode = effectiveTrainingMode();

  return (
    <QuickSessionContext.Provider value={state}>
      {props.children}

      {dialogReset && aggReady && managePitches.length > 0 && (
        <ResetTrainingDialog
          key={dialogReset}
          pitches={managePitches}
          onClose={async () => {
            setDialogReset(undefined);

            if (!managePitches || managePitches.length === 0) {
              return;
            }

            await updatePitches({
              pitches: managePitches,
              includeHitterPresent: false,
              includeLowConfidence: true,
            });
          }}
        />
      )}

      {dialogTraining &&
        aggReady &&
        managePitches.length > 0 &&
        (mode === TrainingMode.Manual ? (
          <TrainingDialogHoC
            key={dialogTraining}
            identifier="QS-TrainingDialog"
            mode={mode}
            pitches={managePitches ?? []}
            threshold={machine.training_threshold}
            onClose={async () => {
              setDialogTraining(undefined);

              if (!managePitches || managePitches.length === 0) {
                return;
              }

              await updatePitches({
                pitches: managePitches,
                includeHitterPresent: false,
                includeLowConfidence: true,
              });
            }}
          />
        ) : (
          <PresetTrainingDialogHoC
            key={dialogTraining}
            identifier="QS-PT-TrainingDialog"
            mode={mode}
            pitches={managePitches}
            onClose={async () => {
              setDialogTraining(undefined);

              if (!managePitches || managePitches.length === 0) {
                return;
              }

              updatePitches({
                pitches: managePitches,
                includeHitterPresent: false,
                includeLowConfidence: true,
              });
            }}
          />
        ))}
    </QuickSessionContext.Provider>
  );
};
