import { DoubleArrowLeftIcon } from '@radix-ui/react-icons';
import { Box, Flex, Separator } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonNavMenu } from 'components/common/layout/nav-menu';
import { AdminSections } from 'components/main/sidebar/admin-sections';
import { SidebarFooter } from 'components/main/sidebar/footer';
import { InstallReminder } from 'components/main/sidebar/install-reminder';
import { SidebarPitchLists } from 'components/main/sidebar/pitch-lists';
import { UserSections } from 'components/main/sidebar/user-sections';
import env from 'config';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { MachineContext } from 'contexts/machine.context';
import { SectionsContext } from 'contexts/sections.context';
import { BETA_MACHINE_INSTALLER } from 'enums/beta';
import { CookieKey, SidebarState } from 'enums/cookies.enums';
import { appearanceImgPath } from 'index';
import { nextSidebarState } from 'interfaces/cookies/i-app.cookie';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { QueueState } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useCallback, useContext, useMemo } from 'react';

const COMPONENT_NAME = 'Sidebar';

const SidebarSeparator = () => (
  <Box mb={RADIX.FLEX.GAP.MAIN_MENU}>
    <Separator mt="2" mb="2" size="4" />
  </Box>
);

export const Sidebar = () => {
  const { app, setCookie } = useContext(CookiesContext);
  const { current } = useContext(AuthContext);
  const { lastStatus, machine } = useContext(MachineContext);

  const expanded = app.sidebar_state === SidebarState.full;

  const toggleCallback = useCallback(() => {
    setCookie(CookieKey.app, {
      sidebar_state: nextSidebarState(app.sidebar_state),
    });
  }, [app.sidebar_state]);

  const { adminSections } = useContext(SectionsContext);

  const hasAdmin = useMemo(
    () => adminSections && adminSections.filter((s) => !s.invisible).length > 0,
    [adminSections]
  );

  const showIntallReminder = useMemo(() => {
    if (!BETA_MACHINE_INSTALLER) {
      return false;
    }

    if (current.role !== UserRole.admin) {
      // not an admin
      return false;
    }

    if (lastStatus.queueState !== QueueState.Active) {
      // hide while d/c from machine
      return false;
    }

    if (!machine.last_installation) {
      // no installation exists
      return false;
    }

    return !machine.last_installation.end_date;
  }, [current.role, lastStatus.queueState, machine.last_installation]);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex
        data-identifier="SidebarWrapper"
        className={RADIX.VFLEX.WRAPPER}
        direction="column"
        // prevents content from resizing this thing
        overflow="hidden"
        p={RADIX.FLEX.PAD.MAIN}
      >
        <Flex
          mb="3"
          data-identifier="SidebarHeader"
          gap={RADIX.FLEX.GAP.SM}
          justify={expanded ? 'between' : 'start'}
          align="center"
        >
          <Box>
            {/* render as 2 different DOM elements to make sure it re-renders immediately */}
            {expanded ? (
              <img
                alt="Trajekt logo"
                title={env.getBuildStamp()}
                src={appearanceImgPath('logo.svg')}
                className="cursor-pointer select-none"
                onClick={toggleCallback}
                style={{
                  height: '32px',
                  width: 'auto',
                }}
              />
            ) : (
              <img
                alt="Trajekt icon"
                title={env.getBuildStamp()}
                src={appearanceImgPath('icon.svg')}
                className="cursor-pointer select-none"
                onClick={toggleCallback}
                style={{
                  height: '32px',
                  width: 'auto',
                }}
              />
            )}
          </Box>

          {expanded && (
            <Box>
              <DoubleArrowLeftIcon
                className="cursor-pointer"
                onClick={toggleCallback}
              />
            </Box>
          )}
        </Flex>

        {showIntallReminder && (
          <Box mt="3" mb="3">
            <InstallReminder expanded={expanded} />
          </Box>
        )}

        <Box
          data-identifier="SidebarScrollable"
          flexGrow="1"
          overflowY="scroll"
          overflowX="hidden"
          mb={RADIX.FLEX.GAP.MAIN_MENU}
          style={{
            scrollbarWidth: 'none',
          }}
        >
          <Box data-identifier="SidebarUserSections">
            <CommonNavMenu orientation="vertical">
              <UserSections />
            </CommonNavMenu>
          </Box>

          {hasAdmin && (
            <>
              <SidebarSeparator />

              <Box data-identifier="SidebarAdminSections">
                <CommonNavMenu orientation="vertical">
                  <AdminSections />
                </CommonNavMenu>
              </Box>
            </>
          )}

          <SidebarSeparator />

          <SidebarPitchLists />
        </Box>

        <SidebarSeparator />

        <Box className="SidebarFooter">
          <SidebarFooter />
        </Box>
      </Flex>
    </ErrorBoundary>
  );
};
