import { User, useAuth0 } from '@auth0/auth0-react';
import { DEFAULT_DELAY_MS, NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { TermsOfServiceDialog } from 'components/common/dialogs/terms-of-service';
import env from 'config';
import { CookiesContext } from 'contexts/cookies.context';
import {
  addMilliseconds,
  addMinutes,
  differenceInMilliseconds,
  isBefore,
  isPast,
  parseISO,
} from 'date-fns';
import format from 'date-fns-tz/format';
import { addSeconds } from 'date-fns/esm';
import { AuthEvent } from 'enums/auth';
import { CookieKey } from 'enums/cookies.enums';
import { LOCAL_TIMEZONE } from 'enums/env';
import { t } from 'i18next';
import {
  DEFAULT_SESSION_UNAUTH,
  ISessionCookie,
} from 'interfaces/cookies/i-session.cookie';
import { DEFAULT_SNAPSHOT_COOKIE } from 'interfaces/cookies/i-snapshot.cookie';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { ERROR_MSGS } from 'lib_ts/enums/errors.enums';
import { KBO_TEAMS } from 'lib_ts/enums/kbo.enums';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import {
  GAME_STATUS_BLACKLIST,
  GameStatus,
  MLB_TEAMS,
} from 'lib_ts/enums/mlb.enums';
import { NPB_TEAMS } from 'lib_ts/enums/npm.enums';
import { League } from 'lib_ts/enums/team.enums';
import { LanguageCode } from 'lib_ts/enums/translation';
import {
  ILoginError,
  ILoginPayload,
  ILoginResult,
  IResetPasswordPayload,
} from 'lib_ts/interfaces/common/i-login';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IGameStatusMsg } from 'lib_ts/interfaces/i-machine-msg';
import { IUser } from 'lib_ts/interfaces/i-user';
import { IReassignOptions } from 'lib_ts/interfaces/pitches/i-pitch-list';
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AuthService } from 'services/auth.service';
import { MainService } from 'services/main.service';
import { SessionEventsService } from 'services/session-events.service';
import { TermsService } from 'services/terms.service';
import { WebSocketService } from 'services/web-socket.service';
import i18n from 'translations/i18n';

const CONTEXT_NAME = 'AuthContext';

const getVersionMatch = (version1: string, version2: string) => {
  const dl = '.';

  const v1Parts = version1.split(dl);
  const v2Parts = version2.split(dl);

  const tiers = ['major', 'minor', 'build', 'patch'];

  const output: { [tier: string]: boolean } = {};

  tiers.forEach((t, i) => {
    output[t] = v1Parts.slice(0, i).join(dl) === v2Parts.slice(0, i).join(dl);
  });

  return output as {
    major: boolean;
    minor: boolean;
    build: boolean;
    patch: boolean;
  };
};

export const getLeagueTeams = (league: League) => {
  switch (league) {
    case League.NipponProfessionalBaseball: {
      return NPB_TEAMS.map((s) => {
        const o: IOption = {
          label: s,
          value: s,
          group: League.NipponProfessionalBaseball,
        };
        return o;
      });
    }

    case League.MajorLeagueBaseball: {
      return MLB_TEAMS.map((s) => {
        const o: IOption = {
          label: s.name,
          value: s.name,
          group: League.MajorLeagueBaseball,
        };
        return o;
      });
    }

    case League.KoreaBaseballOrganization: {
      return KBO_TEAMS.map((s) => {
        const o: IOption = {
          label: s,
          value: s,
          group: League.KoreaBaseballOrganization,
        };
        return o;
      });
    }

    default: {
      return [];
    }
  }
};

/** setting to true will cause the token to refresh every 30 seconds instead of 1 min prior to expiry (only on local) */
const TEST_REFRESH_TOKEN = false;
/** 5 min in ms, how often to check the server for new versions/builds */
const VERSION_CHECK_MS = 5 * 60 * 1_000;

/** expiresIn is provided in ms from now, targets 30 sec before expires in */
const getExpiresFromNow = (expiresIn: number) => {
  return new Date(Date.now() + expiresIn - 30 * 1_000);
};

/** determine whether the given datestring means the user must accept terms before proceeding/closing */
const termsAcceptanceRequired = (ds?: string): boolean => {
  if (!ds) {
    /** user has never accepted TOS before */
    return true;
  }

  const lastAccepted = new Date(ds);
  if (isBefore(lastAccepted, new Date(env.termsRevisionDate))) {
    /** user has not accepted new TOS */
    return true;
  }

  return false;
};

interface IAuthState extends ISessionCookie {}

export interface IAuthContext {
  // as per last WS message
  gameStatus?: GameStatus;
  restrictedGameStatus: boolean;

  current: IAuthState;
  error?: ILoginError;

  loading: boolean;

  /** teams / machines / other users the user can interact with (e.g. for pitch list reassignment) */
  reassignOptions: IReassignOptions;

  readonly effectiveTrainingMode: () => TrainingMode;
  readonly impersonate: (config: { email: string }) => void;
  readonly loginAuth0: () => void;
  readonly signupAuth0: () => void;
  readonly loginLegacy: (config: ILoginPayload) => Promise<ILoginResult>;
  readonly logout: (silent?: boolean) => void;
  readonly logoutAuth0: () => void;
  readonly newSession: () => Promise<boolean>;
  readonly checkTerms: () => void;
  readonly showTermsOfService: () => void;
  readonly reconnectWS: () => void;
  readonly refreshToken: () => Promise<boolean>;
  readonly resetPassword: (config: IResetPasswordPayload) => Promise<boolean>;
  readonly toggleSuperSession: () => Promise<boolean>;
  readonly updateUser: (
    user: Partial<IUser>,
    silently?: boolean
  ) => Promise<IUser>;
  readonly getLeagueTeamOptions: () => IOption[];
}

const DEFAULT: IAuthContext = {
  current: DEFAULT_SESSION_UNAUTH,
  loading: false,

  reassignOptions: {
    teams: [],
    machines: [],
    users: [],
  },

  restrictedGameStatus: false,

  effectiveTrainingMode: () => TrainingMode.Manual,
  impersonate: () => console.error(`${CONTEXT_NAME}: not init`),
  loginAuth0: () => console.error(`${CONTEXT_NAME}: not init`),
  signupAuth0: () => console.error(`${CONTEXT_NAME}: not init`),
  loginLegacy: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  logout: () => console.error(`${CONTEXT_NAME}: not init`),
  logoutAuth0: () => console.error(`${CONTEXT_NAME}: not init`),
  newSession: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  checkTerms: () => console.error(`${CONTEXT_NAME}: not init`),
  showTermsOfService: () => console.error(`${CONTEXT_NAME}: not init`),
  reconnectWS: () => console.error(`${CONTEXT_NAME}: not init`),
  refreshToken: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  resetPassword: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  toggleSuperSession: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  updateUser: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  getLeagueTeamOptions: () => [],
};

export const AuthContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const AuthProvider: FC<IProps> = (props) => {
  const { session, setCookie } = useContext(CookiesContext);

  /** reminder to accept terms won't trigger again until this date is past */
  let termsNotifyDequeue = new Date();

  const [_loading, _setLoading] = useState(DEFAULT.loading);

  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const [_error, _setError] = useState<ILoginError | undefined>(undefined);

  const [_reassignOptions, _setReassignOptions] = useState(
    DEFAULT.reassignOptions
  );

  /** used to trigger a new WS connection (e.g. if you are disconnected) */
  const [_wsCounter, _setWSCounter] = useState(0);

  const [_showTermsOfService, _setShowTermsOfService] = useState(false);

  const [_gameStatus, _setGameStatus] = useState<GameStatus | undefined>();

  const _logoutAuth0 = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const _executeRefreshToken = async (): Promise<boolean> => {
    try {
      const result = await AuthService.getInstance().refresh();

      if (!result?.token) {
        throw new Error('empty token');
      }

      setCookie(CookieKey.session, result, {
        expires: getExpiresFromNow(result.expiresIn),
      });

      return true;
    } catch (e) {
      console.error(e);

      NotifyHelper.error({
        message_md:
          'There was an error refreshing your token. Please logout and login again.',
      });

      setCookie(CookieKey.session, DEFAULT_SESSION_UNAUTH, {
        expires: new Date(),
      });

      return false;
    }
  };

  const _notifyNewVersion = (version: string, updated: string | Date) => {
    if (typeof updated === 'string') {
      updated = parseISO(updated);
    }

    NotifyHelper.warning({
      message_md: [
        `The application has been updated to **v${version}** on ${format(
          updated,
          'yyyy-MM-dd @ h:mm a',
          { timeZone: LOCAL_TIMEZONE }
        )}.`,
        'Please refresh this page using the button below or using your browser when you are ready.',
      ].join('\n\n'),
      delay_ms: 0,
      inbox: true,
      buttons: [
        {
          label: t('common.refresh'),
          onClick: () => window.location.reload(),
          dismissAfterClick: true,
        },
      ],
    });
  };

  const _executeLogout = (reason: AuthEvent, silent?: boolean) => {
    /** log before wiping required session variables */
    if (session.auth) {
      SessionEventsService.postEvent({
        category: 'auth',
        tags: 'logout',
        data: {
          reason,
          silent,
        },
      });
    }

    if (!silent) {
      switch (reason) {
        case 'logout': {
          NotifyHelper.info({ message_md: 'Logging you out, goodbye!' });
          break;
        }

        case 'invalid-token': {
          NotifyHelper.warning({
            message_md: 'Invalid token detected, please login again.',
          });
          break;
        }

        case 'server-offline': {
          NotifyHelper.warning({
            message_md: 'Server could not be reached, please try again later.',
          });
          break;
        }

        default: {
          /** most likely a failed login, notify would be handled in auth service */
          break;
        }
      }
    }

    /** logout of Auth0 if necessary */
    _logoutAuth0();

    /** courtesy call to server */
    AuthService.getInstance().logout();

    /** clearing cookies here will actually result in the logout */
    setCookie(CookieKey.machineCalibration, {});

    setCookie(CookieKey.session, DEFAULT_SESSION_UNAUTH, {
      expires: new Date(),
    });

    setCookie(CookieKey.snapshot, DEFAULT_SNAPSHOT_COOKIE, {
      expires: new Date(),
    });
  };

  const _loginAuth0 = (token: string, user: User) => {
    const errorCallback = (error: ILoginError) => {
      // _executeLogout('failed-auth0-login');
      const iError: ILoginError = { type: error.type, message: error.message };
      _setError(iError);
    };

    return new Promise<ILoginResult>((resolve) => {
      _setLoading(true);
      AuthService.getInstance()
        .loginAuth0(token, user)
        .then((result) => {
          const login = result.success;

          if (login) {
            WebSocketService.init(login.token);

            setCookie(CookieKey.session, login, {
              expires: getExpiresFromNow(login.expiresIn),
              loginEvent: true,
            });

            _setError(result.error);

            resolve(result);
            return;
          }

          if (result.error) {
            errorCallback({
              ...result.error,
              message: result.error
                ? result.error.message
                : ERROR_MSGS.CONTACT_SUPPORT,
            });
          }

          resolve({ error: _error });
        })
        .catch((error) => {
          console.error(error);
          errorCallback({
            type: 'internal',
            message:
              'There was an unexpected problem authenticating via Auth0 Login.',
          });
          resolve({ error: _error });
        })
        .finally(() => _setLoading(false));
    });
  };

  const _handleTerms = (accepted: boolean) => {
    if (accepted) {
      /** date will be updated, let the user proceed once saved */
      TermsService.getInstance()
        .accept()
        .then(() => _setShowTermsOfService(false));
      return;
    }

    /** check whether an existing (previous) acceptance date exists and is still valid */
    TermsService.getInstance()
      .check()
      .then((ds) => {
        if (termsAcceptanceRequired(ds)) {
          /** show a notification but don't spam the area */
          if (isPast(termsNotifyDequeue)) {
            NotifyHelper.warning({
              message_md:
                'You must accept the terms of service before proceeding.',
            });
            termsNotifyDequeue = addMilliseconds(
              new Date(),
              DEFAULT_DELAY_MS + 500
            );
          }

          return;
        }

        /** existing (previous) acceptance date is still valid */
        _setShowTermsOfService(false);
      });
  };

  const _handleGameStatus = (event: CustomEvent) => {
    const data: IGameStatusMsg = event.detail;

    NotifyHelper.debug({
      message_md: `Received game status message (${data.status}).`,
    });

    if (
      (!_gameStatus || !GAME_STATUS_BLACKLIST.includes(_gameStatus)) &&
      GAME_STATUS_BLACKLIST.includes(data.status)
    ) {
      // notify the user if game status changes to a restricted one
      NotifyHelper.warning({
        message_md: 'A game is underway, entering offline mode.',
      });
    }

    _setGameStatus(data.status);
  };

  const _restricted = useMemo(
    () =>
      _gameStatus !== undefined && GAME_STATUS_BLACKLIST.includes(_gameStatus),
    [_gameStatus]
  );

  const state: IAuthContext = {
    current: session,

    loading: _loading || isLoading,
    gameStatus: _gameStatus,

    error: _error,

    reassignOptions: _reassignOptions,

    getLeagueTeamOptions: () => {
      if (session.role === UserRole.admin) {
        // super admins can see every team
        return Object.values(League).flatMap((l) => getLeagueTeams(l));
      }

      // users and team admins see teams based on their team's league
      return getLeagueTeams(session.league);
    },

    checkTerms: () => {
      TermsService.getInstance()
        .check()
        .then((result) => {
          if (termsAcceptanceRequired(result)) {
            _setShowTermsOfService(true);
          }
        });
    },

    effectiveTrainingMode: () =>
      // force users into manual mode if there's a game going on
      _gameStatus === undefined || !GAME_STATUS_BLACKLIST.includes(_gameStatus)
        ? session.training_mode ?? TrainingMode.Quick
        : TrainingMode.Manual,

    restrictedGameStatus: _restricted,

    refreshToken: _executeRefreshToken,

    resetPassword: (payload) => {
      _setLoading(true);

      return AuthService.getInstance()
        .resetPassword(payload)
        .then((result) => {
          if (!result.success) {
            NotifyHelper.warning({
              message_md: result.error ?? t('common.request-failed-msg'),
            });
          }

          return result.success;
        })
        .catch((reason) => {
          console.error(reason);
          NotifyHelper.error({
            message_md: t('common.request-failed-msg'),
          });
          return false;
        })
        .finally(() => _setLoading(false));
    },

    updateUser: async (user, silently) => {
      /** ensure this method is never used to update any other user but the current user */
      user._id = session.userID;

      if (!silently) {
        _setLoading(true);
      }

      const result = await AuthService.getInstance()
        .putUser(user)
        .finally(() => {
          if (!silently) {
            _setLoading(false);
          }
        });

      setCookie(CookieKey.session, {
        ...session,

        menu_mode: result.menu_mode,
        placeholder_folders: result.placeholder_folders,
        plate_show_ellipses: result.plate_show_ellipses,
        preset_training_mode: result.preset_training_mode,
        training_mode: result.training_mode,
        language: result.language,
      });

      return result;
    },

    loginAuth0: () =>
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: window.origin,
        },
      }),
    logoutAuth0: _logoutAuth0,
    signupAuth0: () =>
      loginWithRedirect({
        authorizationParams: {
          screen_hint: 'signup',
          redirect_uri: window.origin,
        },
      }),

    loginLegacy: async (config) => {
      try {
        _setLoading(true);

        const output = await AuthService.getInstance().loginLegacy(config);

        if (!output.success) {
          throw new Error(output.error?.message ?? 'failed login');
        }

        WebSocketService.init(output.success.token);

        /** update auth context */
        setCookie(CookieKey.session, output.success, {
          expires: getExpiresFromNow(output.success.expiresIn),
          loginEvent: true,
        });

        return output;
      } catch (e) {
        console.error(e);

        setCookie(CookieKey.session, DEFAULT_SESSION_UNAUTH, {
          expires: new Date(),
        });

        const output: ILoginResult = {
          error: {
            type: 'internal',
            message:
              'There was an unexpected problem authenticating via Legacy Login.',
          },
        };

        return output;
      } finally {
        _setLoading(false);
      }
    },

    newSession: async () => {
      try {
        if (!session.auth) {
          NotifyHelper.warning({
            message_md: 'You need to be authenticated to perform this action.',
          });
          return false;
        }

        _setLoading(true);

        const result = await AuthService.getInstance().newSession();

        if (!result?.token) {
          throw new Error('empty token');
        }

        /** start the session */
        setCookie(CookieKey.session, result, {
          expires: getExpiresFromNow(result.expiresIn),
        });
        return true;
      } catch (e) {
        console.error(e);

        /** end any existing session */
        setCookie(CookieKey.session, DEFAULT_SESSION_UNAUTH, {
          expires: new Date(),
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    showTermsOfService: () => {
      _setShowTermsOfService(true);
    },

    impersonate: (config) => {
      /** log a session event for the attempt regardless of result */
      SessionEventsService.postEvent({
        category: 'auth',
        tags: 'impersonation',
        data: {
          msg: 'Impersonation attempted',
          admin: session.email,
          role: session.role,
          session: session.session,
          user: config.email,
        },
      });

      /** only super admins can impersonate other users */
      if (session.role !== UserRole.admin) {
        NotifyHelper.warning({
          message_md:
            'You do not have the necessary permissions to perform this action.',
        });
        return;
      }

      _setLoading(true);

      AuthService.getInstance()
        .impersonate(config)
        .then((result) => {
          if (result && result.token && result.mode === 'impostor') {
            NotifyHelper.success({
              message_md: `Starting a new session impersonating user ${result.email}...`,
            });

            setCookie(CookieKey.session, result, {
              expires: getExpiresFromNow(result.expiresIn),
              loginEvent: true,
            });
          } else {
            NotifyHelper.warning({
              message_md: t('common.request-failed-msg'),
            });
            setCookie(CookieKey.session, DEFAULT_SESSION_UNAUTH, {
              expires: new Date(),
            });
          }
        })
        .catch((error) => {
          console.error(error);
          NotifyHelper.error({
            message_md: `There was an error impersonating user ${config.email}.`,
          });
          setCookie(CookieKey.session, DEFAULT_SESSION_UNAUTH, {
            expires: new Date(),
          });
        })
        .finally(() => _setLoading(false));
    },

    toggleSuperSession: async () => {
      try {
        /** only super admins can enter super mode */
        if (session.role !== UserRole.admin) {
          NotifyHelper.warning({
            message_md:
              'You do not have the necessary permissions to perform this action.',
          });
          return false;
        }

        _setLoading(true);

        /** log a session event for the attempt regardless of result */
        SessionEventsService.postEvent({
          category: 'auth',
          tags: 'super',
          data: {
            msg: 'Toggle super session attempted',
            admin: session.email,
            role: session.role,
            session: session.session,
          },
        });

        const result = await AuthService.getInstance().super();

        if (!result?.token) {
          NotifyHelper.warning({
            message_md: t('common.request-failed-msg'),
          });

          throw new Error('failed super session toggle');
        }

        const isSuper = result.mode === 'super';

        NotifyHelper.success({
          message_md: isSuper
            ? 'Starting a super session...'
            : 'Leaving a super session...',
        });

        setCookie(CookieKey.session, result, {
          expires: getExpiresFromNow(result.expiresIn),
          loginEvent: true,
        });

        return true;
      } catch (e) {
        console.error(e);

        setCookie(CookieKey.session, DEFAULT_SESSION_UNAUTH, {
          expires: new Date(),
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    logout: (silent) => _executeLogout('logout', silent),

    reconnectWS: () => {
      _setWSCounter(_wsCounter + 1);
    },
  };

  // start listening to game-status messages
  useEffect(() => {
    WebSocketHelper.on(WsMsgType.S2U_GameStatus, _handleGameStatus);

    return () => {
      WebSocketHelper.remove(WsMsgType.S2U_GameStatus, _handleGameStatus);
    };
  }, []);

  /** clear old and schedule new refresh (if necessary) whenever expires value changes */
  useEffect(() => {
    /** only schedule if logged in */
    if (!session.auth) {
      return;
    }

    if (!session.expires) {
      return;
    }

    const renewAt =
      TEST_REFRESH_TOKEN && env.identifier === 'local'
        ? /** refresh the token every 30 seconds to see what happens in console */
          addSeconds(new Date(), 30)
        : /** target renewal 1 minute before expected expiry time */
          addMinutes(
            typeof session.expires === 'string'
              ? parseISO(session.expires)
              : session.expires,
            -1
          );

    if (isPast(renewAt)) {
      return;
    }

    const difference_ms = differenceInMilliseconds(renewAt, new Date());

    /** save the value here as it may have changed by the time the timeout resolves */
    const pastSession = (() => session.session)();

    /** only ever have a single timer running */
    const timeout = setTimeout(() => {
      /** only refresh if still logged in with same session */
      if (session.auth && session.session === pastSession) {
        _executeRefreshToken();
      }
    }, difference_ms);

    return () => clearTimeout(timeout);
  }, [
    /** don't include _executeTokenRefresh to avoid infinite recursion */
    session.expires,
  ]);

  /** run the following whenever token changes (i.e. after all login steps complete) */
  useEffect(() => {
    if (!session.auth || !session.token) {
      /** default behaviour if not authorized */
      _setReassignOptions(DEFAULT.reassignOptions);
      return;
    }

    AuthService.getInstance()
      .reassignOptions()
      .then((result) => _setReassignOptions(result));
  }, [session.token]);

  // _wsCounter changing implies we're trying to reconnect manually
  useEffect(() => {
    if (_wsCounter === 0) {
      console.debug(`skipped reconnect via WS counter (zero value)`);
      return;
    }

    if (!session.auth) {
      console.debug(`skipped reconnect via WS counter (not auth)`);
      return;
    }

    if (!session.token) {
      console.debug(`skipped reconnect via WS counter (no token)`);
      return;
    }

    WebSocketService.init(session.token);
  }, [_wsCounter]);

  // try reconnecting (if necessary) every 5s
  useEffect(() => {
    const interval = setInterval(() => {
      if (!session.auth) {
        return;
      }

      if (!session.token) {
        return;
      }

      if (WebSocketService.isConnected()) {
        return;
      }

      console.debug(`attempting auto-reconnect via interval (not connected)`);
      WebSocketService.init(session.token);
    }, 10_000);

    return () => clearInterval(interval);
  }, []);

  /** on launch, just check if server is up + what app version is expected */
  useEffect(() => {
    if (env.maintenance) {
      return;
    }

    /** ensures server is online */
    MainService.getInstance()
      .check()
      .then((result) => {
        if (!result) {
          /** auto-logout, e.g. server is down for maintenance or the user's cookies are invalid */
          _executeLogout('server-offline');
          return;
        }

        const versMatch = getVersionMatch(result.version, env.version);

        // no problem when build version still matches
        if (versMatch.build) {
          return;
        }

        _notifyNewVersion(result.version, result.updated);
      })
      .catch((error) => {
        console.error(error);
        _executeLogout('failed-check');
      });
  }, []);

  /** attempt resume if possible */
  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!user) {
      return;
    }

    getAccessTokenSilently({
      authorizationParams: {
        audience: env.integrations.auth0.audience,
      },
    }).then((token) => _loginAuth0(token, user));
  }, [isAuthenticated, user?.sub]);

  // update automatically update i18n language when auth changes
  useEffect(() => {
    if (!session.language) {
      // default and reset behaviour
      if (i18n.language !== LanguageCode.English) {
        // only update if necessary
        i18n.changeLanguage(LanguageCode.English);
      }
      return;
    }

    const nextLanguage = env.enable.change_language
      ? session.language
      : LanguageCode.English;

    if (i18n.language !== nextLanguage) {
      i18n.changeLanguage(nextLanguage);
    }
  }, [session.language]);

  return (
    <AuthContext.Provider value={state}>
      {props.children}
      {_showTermsOfService && (
        <TermsOfServiceDialog
          logoutFn={_executeLogout}
          show={_showTermsOfService}
          onClose={_handleTerms}
        />
      )}
    </AuthContext.Provider>
  );
};
