import { LoginMethod } from 'enums/env';
import { IEnvConfig } from 'interfaces/i-env-config';
import { LanguageCode } from 'lib_ts/enums/translation';

export const ENV_SANDBOX: IEnvConfig = {
  identifier: 'sandbox',
  maintenance: false,
  strict: false,
  use_radix: false,

  production: true,
  languages: [LanguageCode.English],

  debug_messages: false,
  region_redirect: false,
  run_tests: false,

  limits: {
    max_training_shots: 10,
  },

  login_methods: [LoginMethod.Auth0],

  self_url: 'https://sandbox.trajektsports.app',
  server_url: 'https://sandbox.trajektsports.app/node',
  ws_url: 'wss://sandbox.trajektsports.app/wss',

  enable: {
    change_language: false,

    vitals: false,

    sample_lists: false,

    toggle_rapid: true,

    pitch_upload_avg_release: true,
    pitch_upload_avg_chars: false,

    toggle_plate_shots: false,
    toggle_plate_debug: false,
    toggle_plate_safety_controls: true,
    toggle_plate_ellipses: false,

    fire_tags: false,
  },

  integrations: {
    intercom_app_id: 'tas54ol3',

    auth0: {
      domain: 'trajektsports.us.auth0.com',
      audience: 'https://trajektsports.us.auth0.com/api/v2/',
      clientId: 'DqVE1BEpZTxs7COi7gJVPBU6ckbkNqcS',
    },
  },
};
