import { TrackingDevice } from 'lib_ts/enums/training.enums';
import {
  BallType,
  HardwareVersion,
  InstallStepStatus,
} from '../enums/machine.enums';
import { PitchListOwner } from '../enums/pitch-list.enums';
import { IGameStatusClient } from './i-game-status';
import { IMachineSummary } from './i-machine-summary';
import { IMachinePermissions } from './i-permissions';
import { IHotkeyConfig } from './i-remote-controls';
import { IMongoChild } from './mongo/_base';
import { IPresetTrainingSpec } from './training/i-preset-training-spec';

export interface IFirmwareState {
  /** most recent commit id of machine's firmware */
  last_firmware: string;

  /** the last time last_firmware was changed */
  last_firmware_updated: string;
}

export interface IMachineModelDictionary {
  [key: string]: string;
}

export interface IMachineHardwareConfig {
  manufacturer: string;

  wheel_type: string;
  ball_screw_type: string;
  hdpe_type: string;

  wheel_serial_w1: string;
  wheel_serial_w2: string;
  wheel_serial_w3: string;

  position_controller: string;
  motor_controller: string;

  spring_setup: string;
  inserter_guide: string;
  vention_box_location: string;

  ip_address: string;
  mac_address: string;

  [key: string]: string;
}

interface ITrainingDetails {
  /** minimum number of shots for training pitches */
  training_threshold: number;

  tracking_device: TrackingDevice;

  rapsodo_serial: string;

  /** if not true, adds fire option to skip training data creation when not explicitly training */
  enable_continuous_training: boolean;

  precision_train_spec?: IPresetTrainingSpec;

  enable_raspodo_validation: boolean;
}

export interface IInstallationDetails {
  // user email
  started_by: string;
  // user email
  finished_by?: string;

  start_date: string;
  end_date?: string;

  ball_type: BallType;

  // for tagging shots relevant to ball type calibration step
  calibration_id: string;
  // the list used for collecting ball type calibration data
  calibration_list_id?: string;

  // if ball type calibration completes, a model will be created and noted here
  model_id?: string;

  // for tagging shots relevant to repeatability step
  repeatability_id: string;
  // the list used for collecting repeatability data
  repeatability_list_id?: string;

  step_test_shot?: InstallStepStatus;
  step_general_config?: InstallStepStatus;
  step_projector_config?: InstallStepStatus;
  step_ball_type?: InstallStepStatus;
  step_repeatability?: InstallStepStatus;
  step_results?: InstallStepStatus;
}

export interface IMachine
  extends IMongoChild,
    IGameStatusClient,
    Partial<IFirmwareState>,
    ITrainingDetails {
  // there can only be one installation in progress at a time
  last_installation?: IInstallationDetails;

  // date, shots created earlier than this should not be used for training or modelling
  last_hardware_changed?: string;

  hardware_version: HardwareVersion;

  machineID: string;

  /** hint: A user-friendly name for identifying this machine, such as its location or facility. */
  nickname?: string;

  /** for distinguishing between real vs mock machines */
  sandbox?: boolean;

  /** most recent domain that this machine was detected connecting to */
  last_domain: string;

  /** max _created date of fire events belonging to this machine, loaded by admin machines query */
  last_fired?: string;

  /** measured in feet */
  plate_distance: number;

  /** key: model_key, value: model_id */
  model_ids?: IMachineModelDictionary;

  // from the most recent hw config backup
  hw_config_id?: string;
  hardware_config?: IMachineHardwareConfig;

  /** ball type of balls currently loaded into the machine */
  ball_type: BallType;

  /** which ball types should be visible/usable by users of this machine, undefined implies MLB */
  ball_type_options: BallType[];

  suppress_data: boolean;

  notes: string;

  /** if a machine has super access, it will need to access all videos from all teams */
  super: boolean;

  /** strike zone */
  enable_pitch_recap: boolean;

  /** hit distance, speed, angle */
  enable_hit_recap: boolean;

  /** displays details about loaded pitch */
  enable_pitch_preview: boolean;

  /** displays details about active hitter */
  enable_hitter_details: boolean;

  /** if true, changing sections and other movements around the UI will auto-reset the machine's ms */
  enable_auto_reset_ms: boolean;

  enable_rapid_mode: boolean;

  /** if true, selecting Trajekt Vision option in tracking device will be allowed */
  enable_trajekt_vision: boolean;

  default_video_rhp?: string;
  default_video_lhp?: string;

  permissions?: Partial<IMachinePermissions>;

  isMetric?: boolean;

  remote_hotkeys?: Partial<IHotkeyConfig>;
}

/** for admin portal display of machines */
export interface IAdminMachine extends IMachine, IMachineSummary {}

const DEFAULT_TRAINING_DETAILS: ITrainingDetails = {
  training_threshold: 3,

  tracking_device: TrackingDevice.RapsodoV3PRO,

  rapsodo_serial: '',

  enable_raspodo_validation: false,
  enable_continuous_training: false,
};

export const DEFAULT_EDITOR_MACHINE: Partial<IMachine> = {
  machineID: '',

  plate_distance: 56.5,
  ball_type: BallType.MLB,

  super: false,
  suppress_data: false,

  _parent_def: PitchListOwner.Team,
  _parent_field: 'machines',

  hardware_version: HardwareVersion.Arc2023,

  enable_pitch_recap: true,
  enable_hit_recap: true,

  ...DEFAULT_TRAINING_DETAILS,
};

export const DEFAULT_CONTEXT_MACHINE: IMachine = {
  _changed: '',
  _created: '',
  _id: '',
  _parent_def: PitchListOwner.Team,
  _parent_field: 'machines',
  _parent_id: '',
  ball_type_options: [BallType.MLB],
  ball_type: BallType.MLB,
  enable_auto_reset_ms: false,
  enable_hit_recap: true,
  enable_hitter_details: false,
  enable_pitch_preview: false,
  enable_pitch_recap: true,
  enable_rapid_mode: false,
  enable_trajekt_vision: false,
  hardware_version: HardwareVersion.Arc2022,
  ignore_game_status: false,
  last_domain: 'unknown',
  machineID: '',
  model_ids: {},
  notes: '',
  plate_distance: 56.5,
  super: false,
  suppress_data: false,
  ...DEFAULT_TRAINING_DETAILS,
};
