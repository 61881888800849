import { Badge, Box, Flex, Heading } from '@radix-ui/themes';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import {
  PresetTrainingContext,
  PresetTrainingProvider,
} from 'components/machine/dialogs/preset-training/context';
import { PresetTrainingControls } from 'components/machine/dialogs/preset-training/controls';
import { PitchListState } from 'components/sections/pitch-list/store/pitch-list-store';
import { AimingContext, AimingProvider } from 'contexts/aiming.context';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { GlobalContext } from 'contexts/global.context';
import { MachineContext } from 'contexts/machine.context';
import {
  MatchingShotsContext,
  MatchingShotsProvider,
} from 'contexts/pitch-lists/matching-shots.context';
import { TrainingContext, TrainingProvider } from 'contexts/training.context';
import { t } from 'i18next';
import { ITrainingDialog } from 'interfaces/i-training';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useEffect, useState } from 'react';

const COMPONENT_NAME = 'PresetTrainingDialog';

interface IProps extends ITrainingDialog {
  updatePitches?: PitchListState['updatePitches'];
}

export const PresetTrainingDialogHoC = (props: IProps) => {
  const [start] = useState(new Date().toISOString());

  return (
    <TrainingProvider
      mode={props.mode}
      afterTrainingMsg={props.afterTrainingMsg}
    >
      <PresetTrainingProvider>
        <MatchingShotsProvider>
          <AimingProvider newerThan={start}>
            <PresetTrainingDialog {...props} />
          </AimingProvider>
        </MatchingShotsProvider>
      </PresetTrainingProvider>
    </TrainingProvider>
  );
};

const PresetTrainingDialog = (props: IProps) => {
  const { countDialog } = useContext(GlobalContext);
  const { gameStatus, restrictedGameStatus } = useContext(AuthContext);
  const { onEndTraining } = useContext(MachineContext);
  const { pitch } = useContext(AimingContext);
  const { pitchIndex } = useContext(TrainingContext);

  // todo: deprecate this once we convert the underlying thing
  const aimingCx = useContext(AimingContext);
  const authCx = useContext(AuthContext);
  const cookiesCx = useContext(CookiesContext);
  const machineCx = useContext(MachineContext);
  const matchingCx = useContext(MatchingShotsContext);
  const presetCx = useContext(PresetTrainingContext);
  const trainingCx = useContext(TrainingContext);

  useEffect(() => {
    countDialog({ id: props.identifier, open: true });

    return () => countDialog({ id: props.identifier, open: false });
  }, []);

  useEffect(() => {
    return () => onEndTraining();
  }, []);

  const single = props.pitches.length === 1;

  if (restrictedGameStatus) {
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
        <Heading size={RADIX.HEADING.SIZE.LG}>
          Game Status: {gameStatus}
        </Heading>

        <p>
          Training is not possible at this time. Please try again later or
          contact support.
        </p>
      </Flex>
    </ErrorBoundary>;
  }

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog
        identifier={COMPONENT_NAME}
        width={RADIX.DIALOG.WIDTH.XL}
        title={
          <Flex gap={RADIX.FLEX.GAP.SM} align="center">
            <Box mt="1">
              <Heading size={RADIX.HEADING.SIZE.MODAL_TITLE} truncate>
                {single
                  ? t('common.training-pitch-x', {
                      x: pitch?.name ?? t('pl.unnamed-pitch'),
                    })
                  : t('common.training-pitch-n-of-total-x', {
                      n: pitchIndex + 1,
                      total: props.pitches.length,
                      x: pitch?.name ?? t('pl.unnamed-pitch'),
                    })}
              </Heading>
            </Box>

            <Box className="valign-center">
              <Badge>
                {t(
                  pitch?.priority === BuildPriority.Breaks
                    ? 'common.break-priority'
                    : 'common.spin-priority'
                )}
              </Badge>
            </Box>
          </Flex>
        }
        content={
          <PresetTrainingControls
            machineCx={machineCx}
            trainingCx={trainingCx}
            cookiesCx={cookiesCx}
            authCx={authCx}
            matchingCx={matchingCx}
            aimingCx={aimingCx}
            presetCx={presetCx}
            pitches={props.pitches}
            updatePitches={props.updatePitches}
            onFinish={props.onClose}
          />
        }
        onClose={props.onClose}
      />
    </ErrorBoundary>
  );
};
