import { Box, Container } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonDateInput } from 'components/common/form/date';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import env from 'config';
import { MachineContext } from 'contexts/machine.context';
import { parseISO } from 'date-fns';
import { BETA_TRACKMAN_DEVICE, BETA_VISION_DEVICE } from 'enums/beta';
import { t } from 'i18next';
import { BallType } from 'lib_ts/enums/machine.enums';
import {
  TRACKING_DEVICE_OPTIONS,
  TrackingDevice,
} from 'lib_ts/enums/training.enums';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import { useCallback, useContext, useMemo, useState } from 'react';

export const StepGeneralConfig = () => {
  const { machine, update: updateMachine } = useContext(MachineContext);

  const { last_installation } = machine;

  const [loading, setLoading] = useState(false);

  const saveChanges = useCallback(
    async (value: Partial<IMachine>) => {
      try {
        setLoading(true);

        await updateMachine({
          ...value,
          _id: machine._id,
        });
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [machine]
  );

  const deviceOptions = useMemo(() => {
    return TRACKING_DEVICE_OPTIONS.filter((o) => {
      if (o.value === TrackingDevice.TrackmanB1) {
        return BETA_TRACKMAN_DEVICE;
      }

      if (o.value === TrackingDevice.TrajektVision) {
        return BETA_VISION_DEVICE;
      }

      return true;
    });
  }, [TRACKING_DEVICE_OPTIONS, env.production]);

  // only allowed ball types (for this machine) can be selected since a baseline model is still required
  const ballTypeOptions = useMemo(
    () =>
      (machine.ball_type_options ?? [BallType.MLB]).map((t) => ({
        label: t,
        value: t,
      })),
    [machine.ball_type_options]
  );

  const [hardwareDate, setHardwareDate] = useState(
    machine.last_hardware_changed
      ? parseISO(machine.last_hardware_changed)
      : new Date()
  );

  if (!last_installation) {
    return <CommonCallout text="No installation definition!" />;
  }

  return (
    <ErrorBoundary componentName="StepGeneralConfig">
      <Container size="1">
        <CommonFormGrid columns={2}>
          <Box gridColumn="span 2">
            <CommonDateInput
              id="machine-last-hw-changed"
              label="Hardware Changed Date"
              defaultValue={hardwareDate}
              disabled={loading}
              onChange={(date) => {
                if (!date) {
                  NotifyHelper.warning({
                    message_md: t('common.check-inputs-msg'),
                  });
                  return;
                }

                setHardwareDate(date);

                saveChanges({ last_hardware_changed: date.toISOString() });
              }}
              showTime
            />
          </Box>
          <Box>
            <CommonSelectInput
              label="common.tracking-device"
              id="machine-tracking-device"
              options={deviceOptions}
              value={machine.tracking_device}
              disabled={loading}
              shouldChange={(v) => {
                if (
                  v === TrackingDevice.TrajektVision &&
                  !machine.enable_trajekt_vision
                ) {
                  NotifyHelper.warning({
                    message_md: t('common.enable-trajekt-vision-msg'),
                  });
                  return false;
                }

                return true;
              }}
              onChange={(v) => {
                const safeValue = v as TrackingDevice;

                saveChanges({ tracking_device: safeValue });
              }}
            />
          </Box>

          <Box>
            {machine.tracking_device === TrackingDevice.RapsodoV3PRO && (
              <CommonTextInput
                label="common.rapsodo-serial"
                id="machine-rapsodo-serial"
                value={machine.rapsodo_serial}
                disabled={loading}
                onChange={(v) => {
                  const safeValue = (v ?? '').trim();

                  saveChanges({ rapsodo_serial: safeValue });
                }}
                monospace
              />
            )}
          </Box>
          <Box gridColumn="span 2">
            <CommonSelectInput
              id="machine-ball-type"
              name="ball_type"
              label="common.ball-type"
              disabled={loading}
              options={ballTypeOptions}
              value={machine.ball_type}
              onChange={(v) => {
                saveChanges({
                  ball_type: v as BallType,
                  last_installation: {
                    ...last_installation,
                    ball_type: v as BallType,
                  },
                });
              }}
            />
          </Box>
        </CommonFormGrid>
      </Container>
    </ErrorBoundary>
  );
};
