import { Box, Card, Flex, Progress, Text } from '@radix-ui/themes';
import { TrainingHelper } from 'classes/helpers/training-helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import {
  LowerMachineIcon,
  R2FIcon,
} from 'components/common/custom-icon/shorthands';
import {
  DialogButton,
  DialogIconButton,
} from 'components/common/dialogs/button';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MachineAutoFireButton } from 'components/machine/buttons/auto-fire';
import { MachineFireButton } from 'components/machine/buttons/fire';
import { AimingContext, IAimingContext } from 'contexts/aiming.context';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import { CookiesContext, ICookiesContext } from 'contexts/cookies.context';
import {
  IMachineContext,
  MachineContext,
  MachineDialogMode,
} from 'contexts/machine.context';
import { ITrainingContext, TrainingContext } from 'contexts/training.context';
import { DataCollectionStep } from 'enums/data-collector.enums';
import { t } from 'i18next';
import { clamp } from 'lib_ts/classes/math.utilities';
import { getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { SpecialMsPosition } from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import { IPitch } from 'lib_ts/interfaces/pitches';
import React, { useContext } from 'react';

const COMPONENT_NAME = 'DataCollectorStatusBar';

const BTN_CLASS = 'width-80px';

interface IBaseProps {
  pitch: IPitch;

  // e.g. to disable fire and ignore auto-fire when all shots for all pitches have been collected
  isComplete: boolean;
  step: DataCollectionStep;

  currentShot: number;
  totalShots: number;

  // loading should happen automatically, but if the machine runs into an issue and clears the last MS hash,
  // the load pitch button will show up to trigger this fn
  onLoadPitch: () => void;
  onSkipPitch: () => void;
}

interface IProps extends IBaseProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  machineCx: IMachineContext;
  aimingCx: IAimingContext;
  trainingCx: ITrainingContext;
}

interface IState {
  /** for suppressing auto-fire actions until a fireresponse arrives (e.g. after toggling it on) */
  ignoreAutoFire?: boolean;
}

// todo: convert this to a functional component
export const DataCollectorStatusBarHoC = (props: IBaseProps) => {
  const cookiesCx = useContext(CookiesContext);
  const authCx = useContext(AuthContext);
  const machineCx = useContext(MachineContext);
  const aimingCx = useContext(AimingContext);
  const trainingCx = useContext(TrainingContext);

  return (
    <DataCollectorStatusBar
      {...props}
      cookiesCx={cookiesCx}
      authCx={authCx}
      machineCx={machineCx}
      aimingCx={aimingCx}
      trainingCx={trainingCx}
    />
  );
};

class DataCollectorStatusBar extends React.Component<IProps, IState> {
  fireButton?: MachineFireButton;

  constructor(props: IProps) {
    super(props);

    this.state = {
      ignoreAutoFire: true,
    };

    this.handleFireResponse = this.handleFireResponse.bind(this);

    this.renderFireButton = this.renderFireButton.bind(this);
    this.renderSkipButton = this.renderSkipButton.bind(this);
    this.renderSendButton = this.renderSendButton.bind(this);
  }

  componentDidMount(): void {
    WebSocketHelper.on(WsMsgType.M2U_FireResponse, this.handleFireResponse);
  }

  componentWillUnmount(): void {
    WebSocketHelper.remove(WsMsgType.M2U_FireResponse, this.handleFireResponse);
  }

  private async handleFireResponse() {
    /** we can stop ignoring auto-fire status once a fire has already happened */
    if (this.state.ignoreAutoFire) {
      this.setState({
        ignoreAutoFire: false,
      });
    }
  }

  render() {
    const safeProgress = clamp(
      this.props.currentShot / (this.props.totalShots || 1),
      0,
      1
    );

    const isAdmin =
      this.props.authCx.current.role === UserRole.admin ||
      this.props.authCx.current.mode === 'impostor';

    const etaMinutes = TrainingHelper.getETAForShots(
      this.props.totalShots - this.props.currentShot,
      'min'
    );

    const pitchHash = getMSFromMSDict(
      this.props.pitch,
      this.props.machineCx.machine
    ).ms?.matching_hash?.slice(-6);

    const lastHash = this.props.machineCx.lastMS?.matching_hash?.slice(-6);

    return (
      <ErrorBoundary componentName="DataCollectorStatusBar">
        <Card>
          <Flex
            gap={RADIX.FLEX.GAP.SM}
            align="center"
            style={{ height: '100%' }}
          >
            <Flex flexGrow="1" direction="column" gap={RADIX.FLEX.GAP.SM}>
              <Flex gap={RADIX.FLEX.GAP.SM} justify="between">
                <Box
                  title={
                    this.props.authCx.current.role === UserRole.admin
                      ? `Step: ${this.props.step}\nPitch: ${this.props.pitch.name}\nPitch Hash: ${pitchHash}\nLast Hash: ${lastHash}`
                      : undefined
                  }
                >
                  {t('common.pitch')}{' '}
                  {t('common.x-of-y', {
                    x: Math.min(this.props.currentShot, this.props.totalShots),
                    y: this.props.totalShots,
                  })}
                </Box>
                <Box>
                  <Text color={RADIX.COLOR.SECONDARY}>
                    {t('tr.est-x-min-remaining', { x: etaMinutes })}
                  </Text>
                </Box>
              </Flex>
              <Progress value={safeProgress * 100} />
            </Flex>

            {isAdmin && (
              <DialogIconButton
                icon={<R2FIcon />}
                color={RADIX.COLOR.SUPER_ADMIN}
                tooltip={t('common.ready-status').toString()}
                onClick={() =>
                  this.props.machineCx.setDialog(MachineDialogMode.R2F)
                }
              />
            )}

            <DialogIconButton
              color={RADIX.COLOR.WARNING}
              icon={<LowerMachineIcon />}
              tooltip="common.lower-machine"
              onClick={() => {
                this.props.machineCx.setAutoFire(false);
                this.props.machineCx.specialMstarget(SpecialMsPosition.lowered);
              }}
            />

            <MachineAutoFireButton
              as="dialog-button"
              className={BTN_CLASS}
              beforeToggleFn={(newValue) =>
                this.setState({ ignoreAutoFire: newValue })
              }
            />

            {/* load pitch */}
            {!this.props.machineCx.lastMSHash && this.renderSendButton()}
            {/* skip pitch */}
            {this.props.machineCx.lastMSHash && this.renderSkipButton()}
            {/* fire */}
            {this.props.machineCx.lastMSHash && this.renderFireButton()}
          </Flex>
        </Card>
      </ErrorBoundary>
    );
  }

  private renderSendButton() {
    return (
      <DialogButton
        color={RADIX.COLOR.SEND_PITCH}
        className={BTN_CLASS}
        onClick={this.props.onLoadPitch}
        label="common.load-pitch"
      />
    );
  }

  private renderSkipButton() {
    return (
      <DialogButton
        disabled={
          // disable auto-fire before you can skip
          this.props.machineCx.autoFire ||
          this.props.currentShot >= this.props.totalShots
        }
        className={BTN_CLASS}
        onClick={this.props.onSkipPitch}
        label="common.skip"
      />
    );
  }

  private renderFireButton() {
    return (
      <MachineFireButton
        ref={(elem) => (this.fireButton = elem as MachineFireButton)}
        as="dialog-button"
        size={RADIX.BUTTON.SIZE.SM}
        className={BTN_CLASS}
        cookiesCx={this.props.cookiesCx}
        ignoreAutoFire={this.state.ignoreAutoFire}
        machineCx={this.props.machineCx}
        aimingCx={this.props.aimingCx}
        trainingCx={this.props.trainingCx}
        firing={!this.props.isComplete}
        tags="DC"
        onReady={() => {
          if (!this.fireButton) {
            // shouldn't trigger but typescript needs to be satisfied
            return;
          }

          if (
            !this.props.isComplete &&
            this.props.machineCx.autoFire &&
            !this.state.ignoreAutoFire
          ) {
            this.fireButton.performFire('auto', COMPONENT_NAME);
            return;
          }

          // fallback
          this.fireButton.goToReady();
        }}
      />
    );
  }
}
