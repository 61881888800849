import { LoginMethod } from 'enums/env';
import { IEnvConfig } from 'interfaces/i-env-config';
import { LanguageCode } from 'lib_ts/enums/translation';

export const ENV_LOCAL: IEnvConfig = {
  identifier: 'local',
  maintenance: false,
  strict: true,
  use_radix: true,

  production: false,
  languages: [LanguageCode.English, LanguageCode.Japanese, LanguageCode.Korean],

  debug_messages: true,
  region_redirect: false,
  run_tests: false,

  self_url: 'http://localhost:4000',
  server_url: 'http://localhost:3000',
  ws_url: 'ws://localhost:8030',

  limits: {
    max_training_shots: 100,
  },

  login_methods: [LoginMethod.Auth0, LoginMethod.Legacy],

  enable: {
    change_language: true,

    vitals: false,

    sample_lists: true,

    toggle_rapid: true,

    pitch_upload_avg_release: true,
    pitch_upload_avg_chars: true,

    toggle_plate_shots: true,
    toggle_plate_debug: true,
    toggle_plate_safety_controls: true,
    toggle_plate_ellipses: true,

    fire_tags: false,
  },

  integrations: {
    intercom_app_id: 'tas54ol3',

    auth0: {
      domain: 'trajektsports.us.auth0.com',
      audience: 'https://trajektsports.us.auth0.com/api/v2/',
      clientId: '7EdcjlhpQ8V80kWMQOpOR1he3PzTx5NH',
    },
  },
};
