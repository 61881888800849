import { ResetIcon } from '@radix-ui/react-icons';
import { Box, Button, Flex, Grid, Skeleton } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonDateInput } from 'components/common/form/date';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTableButton } from 'components/common/table/button';
import { NoDataPlaceholder } from 'components/common/table/no-data-placeholder';
import { GameDataGameCard } from 'components/sections/game-data/games/card';
import { GameDataContext } from 'contexts/game-data.context';
import { CheckedContext } from 'contexts/layout/checked.context';
import { lightFormat } from 'date-fns';
import { LOCAL_DATE_FORMAT } from 'enums/env';
import { t } from 'i18next';
import { isAppearanceDark } from 'index';
import { ITableNoData } from 'interfaces/tables/no-data';
import { MlbSportId } from 'lib_ts/enums/mlb-stats-api/base.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { useContext, useMemo, useState } from 'react';

const COMPONENT_NAME = 'GameDataPlayers';

const ITEMS_PER_PAGE = 100;

export const GameDataGames = () => {
  const [pages, setPages] = useState(1);

  const checkedCx = useContext(CheckedContext);

  const [resetKey, setResetKey] = useState(Date.now());

  const {
    loading,
    seasons,
    seasonGames,
    filterGlobal,
    mergeFilterGlobal,
    filterGames,
    mergeFilterGames,
  } = useContext(GameDataContext);

  const seasonKey = useMemo(() => Date.now(), [seasons]);

  const games = useMemo(() => {
    return seasonGames

      .filter(
        (g) =>
          filterGames.teamPk === undefined ||
          [g.home.teamPk, g.away.teamPk].includes(filterGames.teamPk)
      )
      .filter(
        (g) =>
          filterGames.date === undefined ||
          g.officialDate.startsWith(filterGames.date)
      );
  }, [seasonGames, filterGames]);

  const teamOptions = useMemo(() => {
    // options per teams, unique per team name
    const teamsDict: { [teamName: string]: IOption } = {};

    seasonGames.forEach((g) => {
      if (teamsDict[g.home.name]) {
        return;
      }

      teamsDict[g.home.name] = {
        label: g.home.name,
        value: g.home.teamPk.toString(),
      };
    });

    const output = Object.values(teamsDict);
    output.sort((a, b) => a.label.localeCompare(b.label));
    return output;
  }, [seasonGames]);

  const isDark = isAppearanceDark();

  const noDataProps: ITableNoData = {
    noDataHeader: 'No Games to Display',
    noDataBody: 'No games match your search',
  };

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex direction="column" gap="3">
        <Flex gap={RADIX.FLEX.GAP.SM}>
          <Grid flexGrow="1" columns="4" gap={RADIX.FLEX.GAP.SM}>
            <CommonSelectInput
              key={`season-${seasonKey}`}
              id="mlb-stats-season"
              name="season"
              placeholder="common.season"
              options={seasons}
              value={filterGlobal.season?.toString()}
              onNumericChange={(v) => {
                if (v === filterGlobal.season) {
                  return;
                }

                mergeFilterGlobal({
                  season: v,
                });
              }}
              disabled={loading}
              optional
              skipSort
            />
            <CommonSelectInput
              key={`sportId-${seasonKey}`}
              id="mlb-stats-sportId"
              name="sportId"
              placeholder="common.level"
              options={[
                { label: 'MLB', value: MlbSportId.MLB.toString() },
                { label: 'MiLB', value: MlbSportId.MiLB.toString() },
              ]}
              value={filterGlobal.sportId.toString()}
              onNumericChange={(v) => {
                if (v === filterGlobal.sportId) {
                  return;
                }

                mergeFilterGlobal({
                  sportId: v,
                });
              }}
              disabled={loading}
              skipSort
            />

            <CommonDateInput
              key={`date-${resetKey}`}
              id="mlb-stats-date"
              placeholder="common.date"
              onChange={(v) => {
                mergeFilterGames({
                  date: v ? lightFormat(v, LOCAL_DATE_FORMAT) : undefined,
                });
              }}
              optional
            />

            <CommonSearchInput
              key={`team-${resetKey}`}
              id="mlb-stats-team"
              name="teamPk"
              title={
                !filterGlobal.season
                  ? 'Select a season first.'
                  : 'Filter games by team.'
              }
              placeholder="common.team"
              options={teamOptions}
              values={filterGames.teamPk ? [filterGames.teamPk.toString()] : []}
              onChange={(v) => {
                const teamPk = v.length > 0 ? parseInt(v[0]) : undefined;

                if (teamPk === filterGames.teamPk) {
                  return;
                }

                mergeFilterGames({
                  teamPk: teamPk,
                });
              }}
              disabled={loading || !filterGlobal.season}
              optional
            />
          </Grid>

          <Box>
            <CommonTableButton
              icon={<ResetIcon />}
              label="common.reset"
              className="btn-block"
              variant="soft"
              color={RADIX.COLOR.NEUTRAL}
              onClick={() => {
                checkedCx.resetChecked();

                // clear the filter values
                mergeFilterGames({
                  date: undefined,
                  teamPk: undefined,
                });

                // clear the select inputs
                setResetKey(Date.now());
              }}
            />
          </Box>
        </Flex>

        <Skeleton loading={loading}>
          {games.length === 0 && <NoDataPlaceholder {...noDataProps} />}

          <Grid columns={{ initial: '1', md: '2', lg: '3' }} gap="3">
            {games
              .filter((_, i) => i < pages * ITEMS_PER_PAGE)
              .map((p) => (
                <GameDataGameCard key={p._id} isDark={isDark} game={p} />
              ))}

            {games.length > pages * ITEMS_PER_PAGE && (
              <Flex
                justify="center"
                gridColumn={{ initial: undefined, md: 'span 2', lg: 'span 3' }}
              >
                <Button
                  onClick={() => setPages(pages + 1)}
                  variant={RADIX.BUTTON.VARIANT.SECONDARY}
                >
                  {t('common.load-more')}
                </Button>
              </Flex>
            )}
          </Grid>
        </Skeleton>
      </Flex>
    </ErrorBoundary>
  );
};
