import { InfoCircledIcon } from '@radix-ui/react-icons';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import {
  DOT_RGB_YELLOW,
  MAX_SHOTS_USED,
  PlateCanvas,
} from 'classes/plate-canvas';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTooltip } from 'components/common/tooltip';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { isAfter, parseISO } from 'date-fns';
import { BASEBALL_PNG_PX, DOT_SIZE_SM, SHOT_OPACITY_DELTA } from 'enums/canvas';
import { TrainStep } from 'enums/training.enums';
import { isAppearanceDark } from 'index';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import { IPitch, IPlateLoc, IPlateLocExt } from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import React from 'react';
import Slider from 'react-input-slider';
import { ShotsService } from 'services/shots.service';

const COMPONENT_NAME = 'TrainingPlateView';

/** seconds to wait before refiring without manual adjustment input */
const AUTO_RETRY_QUICK_SEC = 10;

const DEFAULT_SLIDER: IPlateLoc = {
  plate_x: 0,
  plate_z: 0,
};

interface IProps extends Partial<IPlateLoc> {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  machineCx: IMachineContext;

  step: TrainStep;

  centeredPitch: IPitch;
  shots: IMachineShot[];
  newShotID?: string;

  // irrelevant for manual training
  onUpdateShot: (shot: IMachineShot | undefined) => void;
}

interface IState {
  /** ft */
  slider_x: number;
  /** ft */
  slider_y: number;

  shotIDs: string[];

  sortedShots: IMachineShot[];
}

export const getPovTooltip = () => (
  <CommonTooltip trigger={<InfoCircledIcon />} text_md="pd.batters-pov" />
);

export class TrainingPlateView extends React.Component<IProps, IState> {
  private mountDate = new Date();
  private autoRetryInterval?: any;
  private autoRetrySecRemaining = 0;

  private plate_canvas = PlateCanvas.makeLandscape(isAppearanceDark());

  private mainCanvasNode?: HTMLCanvasElement;
  private shotsCanvasNode?: HTMLCanvasElement;

  constructor(props: IProps) {
    super(props);

    this.state = {
      slider_x: 0,
      slider_y: 0,

      shotIDs: props.shots.map((s) => s._id).sort(),

      sortedShots: [],
    };

    this.drawComplete = this.drawComplete.bind(this);
    this.drawMain = this.drawMain.bind(this);
    this.drawShots = this.drawShots.bind(this);
    this.getPlateLoc = this.getPlateLoc.bind(this);
    this.getSortedShots = this.getSortedShots.bind(this);
    this.onChange = this.onChange.bind(this);
    this.updateShot = this.updateShot.bind(this);
  }

  componentDidMount(): void {
    this.drawMain('componentDidMount');
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const nextState: Partial<IState> = {};

    const allowAdjust =
      (prevProps.step !== TrainStep.ManualAdjust &&
        this.props.step === TrainStep.ManualAdjust) ||
      (prevProps.step !== TrainStep.ManualInput &&
        this.props.step === TrainStep.ManualInput);

    if (allowAdjust) {
      nextState.slider_x = DEFAULT_SLIDER.plate_x;
      nextState.slider_y = DEFAULT_SLIDER.plate_z;

      if (this.props.authCx.current.training_mode !== TrainingMode.Manual) {
        this.autoRetrySecRemaining = AUTO_RETRY_QUICK_SEC;

        clearInterval(this.autoRetryInterval);
        this.autoRetryInterval = setInterval(() => {
          if (this.autoRetrySecRemaining > 0) {
            this.autoRetrySecRemaining--;
            return;
          }

          clearInterval(this.autoRetryInterval);
          this.props.onUpdateShot(undefined);
        }, 1_000);
      }
    }

    const enteredComplete =
      prevProps.step !== TrainStep.Complete &&
      this.props.step === TrainStep.Complete;

    if (enteredComplete) {
      nextState.slider_x = this.plate_canvas.CONFIG.x.default_ft;
      nextState.slider_y = this.plate_canvas.CONFIG.y.default_ft;
    }

    const currentShots = this.props.shots;
    const currentShotIDs = currentShots.map((s) => s._id).sort();

    if (
      this.props.centeredPitch._id !== prevProps.centeredPitch?._id ||
      MiscHelper.hashify(this.state.shotIDs) !==
        MiscHelper.hashify(currentShotIDs)
    ) {
      nextState.shotIDs = currentShotIDs;
    }

    if (Object.keys(nextState).length > 0) {
      this.setState(nextState as any, () => {
        if (enteredComplete) {
          this.drawComplete();
        }
      });
    }
  }

  componentWillUnmount(): void {
    clearInterval(this.autoRetryInterval);
  }

  getPlateLoc(): IPlateLoc {
    const output: IPlateLoc = {
      plate_x: this.state.slider_x,
      plate_z: this.state.slider_y,
    };

    return output;
  }

  async updateShot(newShot: IMachineShot) {
    if (this.props.step !== TrainStep.ManualAdjust) {
      NotifyHelper.warning({
        message_md: 'Cannot update shot when not in manual adjustment step.',
      });
      return;
    }

    /** user has input where the shot landed, according to them */
    if (!this.props.newShotID) {
      NotifyHelper.warning({
        message_md: 'Cannot update shot when no new shot has been detected.',
      });
      return;
    }

    const ms = getMSFromMSDict(
      this.props.centeredPitch,
      this.props.machineCx.machine
    ).ms;

    if (!ms) {
      NotifyHelper.warning({
        message_md: `${COMPONENT_NAME} Failed to find ms from ms dictionary`,
      });
      return;
    }

    const selectedPlate = this.getPlateLoc();

    const rotation = TrajHelper.getAltAzForTranslation(
      newShot.traj,
      {
        px: newShot.traj.px,
        pz: newShot.traj.pz,
      },
      selectedPlate
    );

    const selectedTraj = TrajHelper.getRotatedTrajectory(
      newShot.traj,
      rotation
    );

    const uShot = await ShotsService.getInstance().updateShot(
      this.props.newShotID,
      { user_traj: selectedTraj }
    );

    if (uShot) {
      this.props.onUpdateShot(uShot);
    }
  }

  onChange(pos: { x: number; y: number }) {
    if (
      ![TrainStep.ManualAdjust, TrainStep.ManualInput].includes(this.props.step)
    ) {
      return;
    }

    clearInterval(this.autoRetryInterval);

    this.setState({
      slider_x: pos.x,
      slider_y: pos.y,
    });
  }

  private drawMain(source: string) {
    console.debug(`drawMain (${source})`);

    const canvas = this.mainCanvasNode;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    this.plate_canvas.drawStrikeZoneAutoAdjustArea(ctx);
    this.plate_canvas.drawStrikeZone(ctx);
    this.plate_canvas.drawGround(ctx);
  }

  private drawComplete() {
    const canvas = this.shotsCanvasNode;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    this.plate_canvas.drawCheckmark(ctx);
  }

  drawShots(source: string) {
    console.debug(`drawShots (${source})`);

    this.setState(
      {
        ...this.state,
        sortedShots: this.getSortedShots(),
      },
      () => {
        const canvas = this.shotsCanvasNode;
        if (!canvas) {
          return;
        }

        const ctx = canvas.getContext('2d');
        if (!ctx) {
          return;
        }

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        if (this.props.step === TrainStep.Complete) {
          return;
        }

        this.state.sortedShots
          .map((shot) => {
            const o: IPlateLocExt = {
              ...TrajHelper.getPlateLoc(shot.traj),
              _created: shot._created,
              _id: shot._id,
            };

            return o;
          })
          .forEach((loc, i) => {
            const emphasis = this.props.newShotID === loc._id;
            const opacity = 1 - i * SHOT_OPACITY_DELTA;

            this.plate_canvas.drawDot(ctx, loc, {
              color: `rgba(${DOT_RGB_YELLOW}, ${opacity})`,
              size: DOT_SIZE_SM,
              label: emphasis
                ? `${loc.plate_x.toFixed(1)}', ${loc.plate_z.toFixed(1)}'`
                : undefined,
            });
          });
      }
    );
  }

  /** sort newest first */
  private getSortedShots(limit = MAX_SHOTS_USED) {
    return [...this.props.shots]
      .filter((m) => isAfter(parseISO(m._created), this.mountDate))
      .sort((a, b) => -a._created.localeCompare(b._created))
      .filter((_, i) => i < limit);
  }

  render() {
    /** width and height on the canvas element are not the same as its style width and height
     * affects zoom/scaling of the drawings when there are differences
     */
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <div className="slider">
          <div
            className="sizer"
            style={{
              aspectRatio: '1.6',
              maxHeight: '400px',
              minHeight: '100px',
            }}
          >
            <canvas
              ref={(node) => (this.mainCanvasNode = node as HTMLCanvasElement)}
              width={this.plate_canvas.CONFIG.canvas.width_px}
              height={this.plate_canvas.CONFIG.canvas.height_px}
            />
            <canvas
              ref={(node) => (this.shotsCanvasNode = node as HTMLCanvasElement)}
              width={this.plate_canvas.CONFIG.canvas.width_px}
              height={this.plate_canvas.CONFIG.canvas.height_px}
            />
            <div className="slider-wrapper animate-fade">
              <Slider
                data-testid="QuickTrainPlateLocation"
                data-shots={JSON.stringify(
                  this.state.sortedShots.map((shot) => {
                    const plateLoc = TrajHelper.getPlateLoc(shot.traj);
                    return { x: plateLoc.plate_x, z: plateLoc.plate_z };
                  })
                )}
                axis="xy"
                xstep={this.plate_canvas.CONFIG.x.step}
                xmin={this.plate_canvas.CONFIG.x.min_ft}
                xmax={this.plate_canvas.CONFIG.x.max_ft}
                x={this.state.slider_x}
                ystep={this.plate_canvas.CONFIG.y.step}
                ymin={this.plate_canvas.CONFIG.y.min_ft}
                ymax={this.plate_canvas.CONFIG.y.max_ft}
                y={this.state.slider_y}
                onChange={this.onChange}
                onDragEnd={() => {
                  setTimeout(() => {
                    this.drawShots('onDragEnd');
                  }, 100);
                }}
                styles={{
                  track: {
                    backgroundColor: 'rgba(0, 0, 255, 0)',
                    width: '100%',
                    height: '100%',
                  },
                  thumb: {
                    backgroundColor: 'rgba(0, 255, 0, 0)',
                    backgroundImage: 'url(/img/baseball.png)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    boxShadow: 'none',
                    height: BASEBALL_PNG_PX,
                    width: BASEBALL_PNG_PX,
                    margin: -BASEBALL_PNG_PX / 2,
                    opacity: [
                      TrainStep.ManualAdjust,
                      TrainStep.ManualInput,
                    ].includes(this.props.step)
                      ? 1
                      : 0,
                  },
                }}
                yreverse
              />
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}
