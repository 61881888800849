import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import env from 'config';
import { QueueState, WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { IUserEventMsg, IWSMsg } from 'lib_ts/interfaces/i-machine-msg';
import { IMachineStatusMsg } from 'lib_ts/interfaces/machine-msg/i-machine-status';
import { ICloseEvent, IMessageEvent, w3cwebsocket } from 'websocket';

/** 1 retry every 500 ms, 10 times => max wait of 5 seconds before giving up */
const MAX_ATTEMPTS = 10;
const DELAY_MS = 500;
const NOTIFY_WS_ERROR = false;

let lastToken: string | undefined = undefined;

const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

/** for talking to the machine */
export class WebSocketService {
  private static instance?: WebSocketService;

  static isConnected(): boolean {
    return this.instance?.client.OPEN !== undefined;
  }

  static init(token: string) {
    try {
      if (!token) {
        console.debug(
          `skipped creation of WS instance for token ${token} (empty token)`
        );
        return;
      }

      if (lastToken === token) {
        console.debug(
          `skipped creation of WS instance for token ${token} (matches last token)`
        );
        return;
      }

      if (!WebSocketService.instance) {
        console.debug(`created new WS instance for token *-${token.slice(-6)}`);
        WebSocketService.instance = new WebSocketService(token);
        return;
      }

      const success = WebSocketService.instance.close();

      if (!success) {
        throw new Error('Failed to close WS for init!');
      }

      console.debug(`replaced WS instance for token ${token}`);
      WebSocketService.instance = new WebSocketService(token);
    } catch (e) {
      console.error(e);
    }
  }

  /** should be the only way anything external ever accesses the instance */
  static getInstance(): WebSocketService | undefined {
    return WebSocketService.instance;
  }

  /** for sending messages back to the server */
  private client: w3cwebsocket;

  static async send(type: WsMsgType, data: any, source: string) {
    try {
      const instance = WebSocketService.instance;
      if (!instance) {
        throw new Error(
          `Attempted to send ${type} message via uninitialized instance of WebSocketService (${source})`
        );
      }

      /** wait for open */
      const client = instance.client;

      for (
        let i = 0;
        i <= MAX_ATTEMPTS && client.readyState !== client.OPEN;
        i++
      ) {
        if (i === MAX_ATTEMPTS) {
          throw new Error(
            `WS: websocket was not OPEN after ${MAX_ATTEMPTS} attempts (${source})`
          );
        }

        await delay(DELAY_MS * Math.pow(1.5, i));
      }

      client.send(JSON.stringify({ type: type, data: data }));
    } catch (e) {
      console.error(e);

      if (NOTIFY_WS_ERROR) {
        NotifyHelper.warning({
          message_md:
            'There was a problem sending the WebSocket message. Please try again or refresh your browser.',
        });
      }
    }
  }

  private constructor(token: string) {
    lastToken = token;

    this.client = new w3cwebsocket(`${env.ws_url}?token=${token}`);

    this.client.onopen = () => {
      const data: IUserEventMsg = { type: 'open' };
      WebSocketService.send(WsMsgType.Misc_UserConnection, data, 'open');
    };

    this.client.onmessage = (payload: IMessageEvent) => {
      if (typeof payload.data !== 'string') {
        console.warn({
          event: 'WS received payload with non-string data',
          payload,
        });
        return;
      }

      const message = JSON.parse(payload.data) as IWSMsg;
      if (!message.type) {
        console.error({
          event: 'WS received message with empty type, nothing dispatched',
          message,
        });
        return;
      }

      WebSocketHelper.dispatch(message.type, message.data);
    };

    this.client.onclose = (event: ICloseEvent) => {
      console.debug({
        event: `WS closed`,
        details: event,
      });

      const data: Partial<IMachineStatusMsg> = {
        queueState: QueueState.Disconnected,
      };

      WebSocketHelper.dispatch(WsMsgType.Misc_LocalMachineStatus, {
        data: data,
      });
    };

    this.client.onerror = (error) => {
      console.error(error);
    };
  }

  close(): boolean {
    try {
      switch (this.client.readyState) {
        case this.client.CLOSED:
        case this.client.CLOSING: {
          console.debug(`can't close WS connection (CLOSED or CLOSING)`);
          return false;
        }

        default: {
          console.debug('attempting to close WS connection');
          this.client.close();
          WebSocketService.instance = undefined;
          return true;
        }
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}
