import { NotifyHelper } from 'classes/helpers/notify.helper';
import { t } from 'i18next';
import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import { IMachine, IMachineModelDictionary } from 'lib_ts/interfaces/i-machine';
import { IMachineHardwareConfigBackup } from 'lib_ts/interfaces/i-machine-hw-config-backup';
import { BaseRESTService } from 'services/_base-rest.service';

export class MachinesService extends BaseRESTService {
  private static instance: MachinesService;
  static getInstance(): MachinesService {
    if (!MachinesService.instance) {
      MachinesService.instance = new MachinesService();
    }

    return MachinesService.instance;
  }

  private constructor() {
    super({
      controller: 'machines',
    });
  }

  /** for users, returns machines in user's team */
  async getTeamMachines(): Promise<IMachine[]> {
    return await this.get({
      uri: '',
    });
  }

  /** machineID like 'Sandbox' */
  async getByMachineID(
    machineID: string
  ): Promise<{ machine: IMachine; connected: boolean; calibrated: boolean }> {
    return await this.get({
      uri: machineID,
    });
  }

  async getMachineHWConfigs(
    ids: string[]
  ): Promise<IMachineHardwareConfigBackup[]> {
    return await this.post(
      {
        uri: 'hw-config-backups',
      },
      ids
    );
  }

  /** key is model._id, value is model.name */
  async getModelsDictionary(): Promise<IMachineModelDictionary> {
    return await this.get({
      uri: 'models/dictionary',
    });
  }

  async update(data: Partial<IMachine>): Promise<IMachine | undefined> {
    if (!data._id) {
      NotifyHelper.warning({ message_md: 'Cannot update machine without ID' });
      return;
    }

    return this.put(
      {
        uri: '',
        params: {
          machine_id: data._id,
        } as any,
      },
      data
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? t('common.request-failed-msg'),
          });
          return undefined;
        }

        return result.data as IMachine;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });
        return undefined;
      });
  }
}
