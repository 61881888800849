import { FileIcon, PlusIcon } from '@radix-ui/react-icons';
import { Badge, Card, Flex } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchDesignHelper } from 'classes/helpers/pitch-design.helper';
import { CopyPitchesDialog } from 'components/common/dialogs/copy-pitches';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonContentWithSidebar } from 'components/common/layout/content-with-sidebar';
import { PresetTrainingDialogHoC } from 'components/machine/dialogs/preset-training';
import { TrainingDialogHoC } from 'components/machine/dialogs/training';
import { SectionHeader } from 'components/sections/header';
import { BallFlightDesigner } from 'components/sections/pitch-design/ball-flight-designer';
import { MainForm } from 'components/sections/pitch-design/main-form';
import { SeamOrientation } from 'components/sections/pitch-design/seam-orientation';
import { PitchDesignSidebar } from 'components/sections/pitch-design/sidebar';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { PitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { DirtyForm, SectionsContext } from 'contexts/sections.context';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { AimingHelper } from 'lib_ts/classes/aiming.helper';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IBuildPitchChars, IPitch } from 'lib_ts/interfaces/pitches';
import { useContext, useEffect, useMemo, useState } from 'react';
import { PitchListsService } from 'services/pitch-lists.service';
import { PitchesService } from 'services/pitches.service';
import { SessionEventsService } from 'services/session-events.service';

const COMPONENT_NAME = 'PitchDesign';

// for beta to work with prod server, this must be false and we must send users back to the parent list on update
const ENABLE_REPEATED_UPDATES = false;

export const PitchDesign = () => {
  const { activeModel } = useContext(MachineContext);
  const { current } = useContext(AuthContext);

  const {
    ball,
    priority,
    reference,
    workingChars,
    getPitchPayload,
    mergeBall,
    mergeWorkingChars,
  } = useContext(PitchDesignContext);

  // trigger a first build (to populate working chars ms)
  useEffect(() => {
    if (!current.auth) {
      return;
    }

    if (!activeModel) {
      return;
    }

    mergeBall({
      trigger: `${COMPONENT_NAME} startup`,
      ball: {},
      markDirty: false,
      rebuild: true,
    });
  }, [current.auth, activeModel]);

  const { active, lastListID, clearDirtyForm, tryChangeSection } =
    useContext(SectionsContext);

  const machineCx = useContext(MachineContext);

  const { effectiveTrainingMode } = useContext(AuthContext);

  const { readyToTrain } = useContext(MatchingShotsContext);

  const [selected, setSelected] = useState<Partial<IPitch>>();

  const [dialogTraining, setDialogTraining] = useState<number>();

  const [dialogSave, setDialogSave] = useState<number>();

  const trainingDialog = useMemo(() => {
    if (!dialogTraining) {
      return <></>;
    }

    if (!selected) {
      return <></>;
    }

    const mode = effectiveTrainingMode();

    if (mode === TrainingMode.Manual) {
      return (
        <TrainingDialogHoC
          key={dialogTraining}
          identifier="PD-TrainingDialog"
          mode={mode}
          pitches={[selected]}
          threshold={machineCx.machine.training_threshold}
          onClose={() => setDialogTraining(undefined)}
        />
      );
    }

    return (
      <PresetTrainingDialogHoC
        key={dialogTraining}
        identifier="PD-PT-TrainingDialog"
        mode={mode}
        pitches={[selected]}
        onClose={() => setDialogTraining(undefined)}
      />
    );
  }, [
    dialogTraining,
    selected,
    machineCx.machine.training_threshold,
    effectiveTrainingMode,
  ]);

  const saveDialog = useMemo(() => {
    if (!dialogSave) {
      return <></>;
    }

    if (!selected) {
      return <></>;
    }

    return (
      <CopyPitchesDialog
        key={dialogSave}
        title={
          <Flex gap="2" align="center">
            {t('pd.add-to-pitch-list')}
            <Badge>{PitchDesignHelper.getDisplayBuildPriority(priority)}</Badge>
          </Flex>
        }
        identifier="PitchDesignSavePitchDialog"
        pitches={[selected]}
        description={t('pd.msg-new-pitch-ready').toString()}
        onCreated={() => {
          clearDirtyForm(DirtyForm.PitchDesign);

          SessionEventsService.postEvent({
            category: 'pitch',
            tags: 'designer',
            data: {
              action: 'save',
              count: 1,
            },
          });

          setDialogSave(undefined);
        }}
        onClose={() => setDialogSave(undefined)}
      />
    );
  }, [dialogSave, selected, priority]);

  const body = useMemo(() => {
    const pitchID = active.fragments?.[0];

    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        <Card size="2">
          <MainForm showRef={!!pitchID} />
        </Card>

        <Card size="2">
          <SeamOrientation showRef={!!pitchID} />
        </Card>

        {workingChars.traj && (
          <BallFlightDesigner
            traj={workingChars.traj}
            onUpdatePlate={(plate) => {
              if (!workingChars.bs) {
                return;
              }

              if (!workingChars.traj) {
                return;
              }

              if (!workingChars.ms) {
                return;
              }

              const aimed = AimingHelper.aimWithoutShots({
                chars: {
                  bs: workingChars.bs,
                  traj: workingChars.traj,
                  ms: workingChars.ms,
                  priority: workingChars.priority ?? BuildPriority.Spins,
                },
                release: {
                  px: ball.px ?? 0,
                  pz: ball.pz ?? 0,
                },
                plate_location: plate,
              });

              mergeWorkingChars(aimed);
            }}
            // does not need to trigger a build via python
            onUpdateRelease={(pos) => {
              mergeBall({
                trigger: `${COMPONENT_NAME} onUpdateRelease`,
                ball: {
                  px: pos.px,
                  pz: pos.pz,
                },
                markDirty: true,
                rebuild: false,
              });

              // manually merge the new value with existing value because build is skipped
              const chars: Partial<IBuildPitchChars> = {
                ...workingChars,
              };

              if (chars.bs && chars.traj && chars.ms && chars.plate) {
                chars.bs.px = pos.px;
                chars.bs.pz = pos.pz;

                chars.traj.px = pos.px;
                chars.traj.pz = pos.pz;

                const aimed = AimingHelper.aimWithoutShots({
                  chars: {
                    bs: chars.bs,
                    ms: chars.ms,
                    traj: chars.traj,

                    seams: chars.seams,
                    breaks: chars.breaks,
                    priority: priority,
                  },
                  release: {
                    px: chars.bs.px,
                    pz: chars.bs.pz,
                  },
                  plate_location: chars.plate,
                });

                mergeWorkingChars(aimed);
              }
            }}
          />
        )}
      </Flex>
    );
  }, [active, workingChars]);

  const isNew = !reference._id;

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex direction="column" gap={RADIX.FLEX.GAP.SECTION}>
        <SectionHeader
          header={t(isNew ? 'common.create-pitch' : 'common.update-pitch')}
          badge={reference.name}
          mainAction={{
            label: 'common.train-pitch',
            color: RADIX.COLOR.TRAIN_PITCH,
            disabled: !readyToTrain(),
            onClick: () => {
              const payload = getPitchPayload();

              if (!payload) {
                console.warn('no payload for train pitch');
                return;
              }

              setSelected(payload);
              setDialogTraining(Date.now());
            },
          }}
          actions={[
            {
              label: 'pd.add-to-pitch-list',
              prefixIcon: <PlusIcon />,
              invisible: !isNew,
              onClick: () => {
                const payload = getPitchPayload();

                if (!payload) {
                  console.warn('no payload for add pitch');
                  return;
                }

                setSelected(payload);
                setDialogSave(Date.now());
              },
            },
            {
              label: 'common.update-pitch',
              prefixIcon: <FileIcon />,
              invisible: isNew,
              onClick: async () => {
                if (!reference._id) {
                  console.warn('no reference pitch _id');
                  return;
                }

                if (!reference._parent_id) {
                  console.warn('no reference pitch _parent_id');
                  return;
                }

                const payload = getPitchPayload();
                if (!payload) {
                  console.warn('no payload for update pitch');
                  return;
                }

                const newPitch: IPitch = {
                  // take everything from the original pitch
                  ...reference,
                  // override specific fields with payload results
                  ...payload,
                };

                // add a new pitch that looks like the existing one
                if (ENABLE_REPEATED_UPDATES) {
                  const result =
                    await PitchesService.getInstance().create(newPitch);

                  if (!result) {
                    NotifyHelper.warning({
                      message_md: t('pd.error-building-pitch-for-updating'),
                    });
                    return;
                  }

                  NotifyHelper.success({
                    message_md: t('common.x-updated', {
                      x: result.name ?? t('common.pitch'),
                    }),
                  });

                  // mark the old pitch as deleted
                  PitchesService.getInstance().deletePitches([reference._id]);

                  // update URL fragment to the new pitch's ID
                  tryChangeSection({
                    trigger: `${COMPONENT_NAME} > updated pitch`,
                    section: SectionName.Pitches,
                    subsection: SubSectionName.Design,
                    fragments: [result._id],
                    ignoreDirty: true,
                  });
                  return;
                } else {
                  // todo: deprecate this block once we update prod server
                  const result =
                    await PitchListsService.getInstance().postPitchesToList({
                      listID: reference._parent_id,
                      data: [newPitch],
                    });

                  if (!result.success) {
                    NotifyHelper.warning({
                      message_md: t('pd.error-building-pitch-for-updating'),
                    });
                    return;
                  }

                  NotifyHelper.success({
                    message_md: t('common.x-updated', {
                      x: payload.name ?? t('common.pitch'),
                    }),
                  });

                  // mark the old pitch as deleted
                  await PitchesService.getInstance().deletePitches([
                    reference._id,
                  ]);

                  // go to the pitch list after saving the update
                  tryChangeSection({
                    trigger: `${COMPONENT_NAME} > updated pitch`,
                    section: SectionName.Pitches,
                    subsection: SubSectionName.List,
                    fragments: [reference._parent_id],
                    ignoreDirty: true,
                  });
                }
              },
            },
          ]}
          breadcrumbs={
            lastListID
              ? [
                  {
                    label: 'common.pitches',
                    onClick: () =>
                      tryChangeSection({
                        section: SectionName.Pitches,
                        subsection: SubSectionName.Library,
                        trigger: `${COMPONENT_NAME} > breadcrumbs`,
                      }),
                  },
                  {
                    label: 'common.pitch-list',
                    onClick: () =>
                      tryChangeSection({
                        section: SectionName.Pitches,
                        subsection: SubSectionName.List,
                        fragments: [lastListID],
                        trigger: `${COMPONENT_NAME} > breadcrumbs`,
                      }),
                  },
                  {
                    label: 'common.update-pitch',
                  },
                ]
              : [
                  {
                    label: 'common.pitches',
                    onClick: () =>
                      tryChangeSection({
                        section: SectionName.Pitches,
                        subsection: SubSectionName.Library,
                        trigger: `${COMPONENT_NAME} > breadcrumbs`,
                      }),
                  },
                  {
                    label: 'common.create-pitch',
                  },
                ]
          }
        />

        <CommonContentWithSidebar left={body} right={<PitchDesignSidebar />} />
      </Flex>

      {saveDialog}
      {trainingDialog}
    </ErrorBoundary>
  );
};
