import { IHitterSessionAdvancedFilters } from 'interfaces/i-hitter-session-filters';
import { HitOutcomeCode } from 'lib_ts/enums/hitters.enums';
import { PitchType } from 'lib_ts/enums/pitches.enums';
import {
  ISessionHit,
  ISessionHitSummary,
} from 'lib_ts/interfaces/i-session-hit';
import { HittersService } from 'services/hitters.service';
import { create } from 'zustand';

interface AnalyticsState {
  loading: boolean;
  error: string | null;

  // Table data
  machineSessions: ISessionHitSummary[];
  hitterSession: ISessionHit[];
  hitterSessionSummary: ISessionHitSummary | null;

  // Hitting Report filters
  batterFilter: string[];
  startDateFilter: string[];

  // Hitter Session filters
  pitchTypeFilter: PitchType[];
  outcomeFilter: HitOutcomeCode[];
  advancedFilters: IHitterSessionAdvancedFilters | null;
}

interface AnalyticsActions {
  // Data fetchers
  fetchMachineSessions: (params: {
    machineID: string;
  }) => Promise<ISessionHitSummary[]>;
  fetchHitterSession: (params: {
    hitterID: string;
    sessionID: string;
  }) => Promise<ISessionHit[]>;

  // Hitting Report filter setters
  setBatterFilter: (filter: string[]) => void;
  setStartDateFilter: (filter: string[]) => void;

  // Hitter Session filter setters
  setPitchTypeFilter: (filter: AnalyticsState['pitchTypeFilter']) => void;
  setOutcomeFilter: (filter: AnalyticsState['outcomeFilter']) => void;
  setAdvancedFilters: (filter: AnalyticsState['advancedFilters']) => void;

  reset: () => void;
}

type AnalyticsStore = AnalyticsState & AnalyticsActions;

const initialState: AnalyticsState = {
  loading: false,
  error: null,

  machineSessions: [],
  hitterSession: [],
  hitterSessionSummary: null,

  batterFilter: [],
  startDateFilter: [],

  pitchTypeFilter: [],
  outcomeFilter: [],
  advancedFilters: null,
};

const useAnalyticsStore = create<AnalyticsStore>((set) => ({
  ...initialState,
  fetchMachineSessions: async ({ machineID }) => {
    set({ loading: true, error: null });

    try {
      const response = await HittersService.getInstance().getMachineSessions({
        machineID: machineID,
        limit: 100,
      });

      set({
        loading: false,
        machineSessions: response,
      });

      return response;
    } catch (error) {
      set({ error: 'Failed to fetch machine sessions', loading: false });
      return [];
    }
  },
  fetchHitterSession: async ({ hitterID, sessionID }) => {
    set({
      loading: true,
      error: null,
      hitterSessionSummary: null,
      hitterSession: [],
      // Reset filters when we change sessions
      pitchTypeFilter: [],
      outcomeFilter: [],
      advancedFilters: null,
    });

    try {
      const response = await HittersService.getInstance().getHitterSession({
        hitterID,
        sessionID,
      });

      set((state) => {
        // machineSessions are fetched upon Analytics mount
        // Find the summary for the current session

        const hitterSessionSummary =
          state.machineSessions.find((machineSession) => {
            // Some machine sessions don't have a hitterID
            if (machineSession.session && machineSession.hitterID) {
              return (
                machineSession.session === sessionID &&
                machineSession.hitterID === hitterID
              );
            }

            return machineSession.session === sessionID;
          }) ?? null;

        return {
          loading: false,
          hitterSession: response,
          hitterSessionSummary,
        };
      });

      return response;
    } catch (error) {
      set({ error: 'Failed to fetch hitter session', loading: false });
      return [];
    }
  },

  setBatterFilter: (batterFilter) => set({ batterFilter }),
  setStartDateFilter: (startDateFilter) => set({ startDateFilter }),

  setPitchTypeFilter: (pitchTypeFilter) => set({ pitchTypeFilter }),
  setOutcomeFilter: (outcomeFilter) => set({ outcomeFilter }),
  setAdvancedFilters: (advancedFilters) => set({ advancedFilters }),

  reset: () => set(initialState),
}));

export default useAnalyticsStore;
