import { Flex, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { TrainingHelper } from 'classes/helpers/training-helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonSwitchInput } from 'components/common/form/switch';
import { SettingForm } from 'components/common/settings-dialog/form';
import { SettingRow } from 'components/common/settings-dialog/row';
import env from 'config';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { MachineContext } from 'contexts/machine.context';
import { CookieKey, DragDropEngine } from 'enums/cookies.enums';
import { t } from 'i18next';
import { fallbackAppearance, setAppearance } from 'index';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import { RadixAppearance } from 'lib_ts/enums/radix-ui';
import { LANGUAGE_OPTIONS, LanguageCode } from 'lib_ts/enums/translation';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import { useContext, useState } from 'react';

const COMPONENT_NAME = 'InterfaceSection';

export const InterfaceSection = () => {
  const { app, setCookie } = useContext(CookiesContext);
  const {
    machine,
    checkActive,
    update: updateMachine,
  } = useContext(MachineContext);
  const { current, updateUser } = useContext(AuthContext);

  const [languageOptions] = useState(
    LANGUAGE_OPTIONS.filter(
      (o) =>
        // always include the user's language even if not enabled for this env
        current.language === o.value ||
        // include all languages enabled for this env
        env.languages.includes(o.value)
    )
  );

  const [isTouch] = useState(navigator.maxTouchPoints > 0);
  const [appearance] = useState(fallbackAppearance(RadixAppearance.Dark));

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <SettingForm>
        {languageOptions.length > 1 && (
          <SettingRow
            separatorAfter
            header="common.interface-language"
            description={
              <Text>{t('common.interface-language-description')}</Text>
            }
            input={
              <CommonSelectInput
                id="interface-language"
                name="language"
                data-value={current.language}
                options={languageOptions}
                value={current.language}
                onChange={(v) => {
                  const code = v as LanguageCode;

                  // updating the current user will automatically cascade to i18n language upon success
                  updateUser({
                    _id: current.userID,
                    language: code,
                  });
                }}
              />
            }
          />
        )}

        <SettingRow
          header="settings.appearance"
          description={<Text>{t('settings.appearance-msg')}</Text>}
          input={
            <CommonSelectInput
              id="interface-appearance"
              name="appearance"
              data-value={appearance}
              className="capitalize"
              options={[
                {
                  label: t('settings.dark-mode'),
                  value: RadixAppearance.Dark,
                },
                {
                  label: t('settings.light-mode'),
                  value: RadixAppearance.Light,
                },
                {
                  label: t('settings.system-mode'),
                  value: RadixAppearance.Inherit,
                },
              ]}
              value={appearance}
              onChange={(v) => {
                setAppearance({
                  value: v as RadixAppearance,
                  autoReload: false,
                });
              }}
            />
          }
        />

        <SettingRow
          separatorBefore
          header="common.training-mode"
          description={<Text>{t('settings.training-mode-msg')}</Text>}
          input={
            <CommonSelectInput
              id="interface-training-mode"
              name="training_mode"
              data-value={current.training_mode}
              className="capitalize"
              options={[
                {
                  label: t('settings.auto-train'),
                  value: TrainingMode.Quick,
                },
                {
                  label: t('settings.manual-train'),
                  value: TrainingMode.Manual,
                },
              ]}
              value={current.training_mode}
              onChange={(v) => {
                const mode = v as TrainingMode;
                const shots = TrainingHelper.minShotsForMode(mode);

                // save the setting to the database (to persist across sessions)
                updateUser({ training_mode: mode });

                // update the setting to the cookies
                setCookie(CookieKey.app, {
                  shots: shots,
                });

                // only update machine if user is active
                if (checkActive(true)) {
                  const m: IMachine = {
                    ...machine,
                    training_threshold: shots,
                  };

                  updateMachine(m);
                }
              }}
            />
          }
        />

        {isTouch && (
          <SettingRow
            separatorBefore
            header="common.drag-drop-mode"
            description={<Text>{t('settings.drag-drop-mode-msg')}</Text>}
            input={
              <Flex justify="end">
                <CommonSwitchInput
                  id="interface-touch"
                  checked={app.drag_drop_engine === DragDropEngine.touch}
                  onCheckedChange={(v) => {
                    setCookie(CookieKey.app, {
                      drag_drop_engine: v
                        ? DragDropEngine.touch
                        : DragDropEngine.click,
                    });

                    NotifyHelper.info({
                      message_md: t('settings.refresh-page-for-effect-msg'),
                      delay_ms: 0,
                      inbox: true,
                    });
                  }}
                />
              </Flex>
            }
          />
        )}

        <SettingRow
          separatorBefore
          header="common.placeholder-folders"
          description={<Text>{t('settings.placeholder-folders-msg')}</Text>}
          input={
            <Flex justify="end">
              <CommonSwitchInput
                id="interface-placeholder-folders"
                data-testid="PlaceholderFoldersToggle"
                checked={current.placeholder_folders}
                onCheckedChange={(v) => {
                  updateUser({
                    placeholder_folders: v,
                  });
                }}
              />
            </Flex>
          }
        />

        {env.enable.toggle_plate_ellipses && (
          <SettingRow
            separatorBefore
            header="common.plate-ellipses"
            description={<Text>{t('settings.plate-ellipses-msg')}</Text>}
            input={
              <Flex justify="end">
                <CommonSwitchInput
                  id="interface-show-ellipses"
                  checked={current.plate_show_ellipses}
                  onCheckedChange={(v) => {
                    updateUser({
                      plate_show_ellipses: v,
                    });
                  }}
                />
              </Flex>
            }
          />
        )}

        {env.enable.toggle_plate_debug && (
          <SettingRow
            separatorBefore
            header="common.plate-debug"
            description={<Text>{t('settings.plate-debug-msg')}</Text>}
            input={
              <Flex justify="end">
                <CommonSwitchInput
                  id="interface-show-plate-debug"
                  checked={app.plate_show_debug}
                  onCheckedChange={(v) =>
                    setCookie(CookieKey.app, {
                      plate_show_debug: v,
                    })
                  }
                />
              </Flex>
            }
          />
        )}

        {env.enable.toggle_plate_safety_controls && (
          <>
            <SettingRow
              separatorBefore
              header="common.safety-limits"
              description={<Text>{t('settings.safety-limits-msg')}</Text>}
              input={
                <Flex justify="end">
                  <CommonSwitchInput
                    id="interface-ellipse-limits"
                    checked={app.plate_limit_by_ellipse}
                    onCheckedChange={(v) =>
                      setCookie(CookieKey.app, {
                        plate_limit_by_ellipse: v,
                      })
                    }
                  />
                </Flex>
              }
            />
            <SettingRow
              separatorBefore
              header="common.safety-warnings"
              description={<Text>{t('settings.safety-warnings-msg')}</Text>}
              input={
                <Flex justify="end">
                  <CommonSwitchInput
                    id="interface-ellipse-warnings"
                    checked={app.plate_show_ellipse_warnings}
                    onCheckedChange={(v) =>
                      setCookie(CookieKey.app, {
                        plate_show_ellipse_warnings: v,
                      })
                    }
                  />
                </Flex>
              }
            />
          </>
        )}

        <SettingRow
          separatorBefore
          header="common.debug-messages"
          description={<Text>{t('settings.debug-messages-msg')}</Text>}
          input={
            <Flex justify="end">
              <CommonSwitchInput
                id="interface-debug-toasts"
                checked={app.enable_debug_toasts}
                onCheckedChange={(v) => {
                  setCookie(CookieKey.app, {
                    enable_debug_toasts: v,
                  });
                }}
              />
            </Flex>
          }
        />
      </SettingForm>
    </ErrorBoundary>
  );
};
