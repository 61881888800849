import { PlusIcon, TrashIcon } from '@radix-ui/react-icons';
import { Badge } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTableHoC } from 'components/common/table';
import { SectionHeader } from 'components/sections/header';
import { HitterToolbar } from 'components/sections/hitter-library/toolbar';
import { ICookiesContext } from 'contexts/cookies.context';
import { IGlobalContext } from 'contexts/global.context';
import { IHittersContext } from 'contexts/hitters.context';
import { ACTIONS_KEY, CrudAction, TABLES } from 'enums/tables';
import { t } from 'i18next';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IMenuAction } from 'interfaces/i-menus';
import { ITableCheckable } from 'interfaces/tables/checking';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { imperialToText } from 'lib_ts/classes/math.utilities';
import { HitterSide } from 'lib_ts/enums/hitters.enums';
import { PLAYER_LEVELS } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IHitter } from 'lib_ts/interfaces/i-hitter';
import React from 'react';

const IDENTIFIER = TableIdentifier.HitterList;

interface IProps {
  globalCx: IGlobalContext;
  cookiesCx: ICookiesContext;
  hittersCx: IHittersContext;
}

interface IState {
  selectedHitters: IHitter[];
  editorModel?: IHitter;
}

const DEFAULT_STATE: IState = {
  selectedHitters: [],
};

const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

export class HitterLibrary extends React.Component<IProps, IState> {
  private readonly BASE_COLUMNS: ITableColumn[] = [
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      actions: [
        {
          label: 'common.edit',
          invisibleFn: () => true,
          onClick: (m: IHitter) => {
            this.props.hittersCx.openCrudDialog({
              key: Date.now(),
              action: CrudAction.Update,
              models: [m],
            });
          },
        },
        {
          label: 'common.delete',
          color: RADIX.COLOR.DANGER,
          onClick: (m: IHitter) => {
            this.props.hittersCx.openCrudDialog({
              key: Date.now(),
              action: CrudAction.Delete,
              models: [m],
            });
          },
        },
      ],
    },
    {
      label: 'common.created',
      key: '_created',
      dataType: 'date',
    },
    {
      label: 'common.name',
      key: 'name',
    },
    {
      label: 'common.side',
      key: 'side',
      formatFn: (h: IHitter) => {
        const isRight = h.side === HitterSide.RHH;

        return (
          <Badge color={isRight ? RADIX.COLOR.RIGHT : RADIX.COLOR.LEFT}>
            {t(isRight ? 'hitters.rhh' : 'hitters.lhh')}
          </Badge>
        );
      },
    },
    {
      label: 'common.level',
      key: 'level',
      formatFn: (m: IHitter) => {
        const label = PLAYER_LEVELS.find((o) => o.value === m.level)?.label;
        return label ? t(label) : '--';
      },
      sortRowsFn: (a: IHitter, b: IHitter, dir: number) => {
        const aIndex = PLAYER_LEVELS.findIndex((l) => l.value === a.level);
        const bIndex = PLAYER_LEVELS.findIndex((l) => l.value === b.level);
        return (aIndex > bIndex ? 1 : -1) * dir;
      },
    },
    {
      label: 'common.height',
      key: 'height_ft',
      align: 'right',
      formatFn: (m: IHitter) =>
        m.height_ft !== undefined ? imperialToText(m.height_ft) : '--',
      sortRowsFn: (a: IHitter, b: IHitter, dir: number) =>
        ((a.height_ft ?? 0) > (b.height_ft ?? 0) ? 1 : -1) * dir,
    },
  ];

  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.getCheckedMenuActions = this.getCheckedMenuActions.bind(this);
  }

  componentWillUnmount(): void {
    // reset the filters to avoid negative interactions with other sections (e.g. pitch list doesn't show the side filter)
    this.props.hittersCx.setFilters({});
  }

  render() {
    const data = this.props.hittersCx.filtered;

    const pagination: ITablePageable = {
      identifier: IDENTIFIER,
      total: data.length,
      enablePagination: true,
      pageSizes: PAGE_SIZES,
    };

    const sort: ITableSortable = {
      enableSort: true,
      defaultSort: {
        key: 'name',
        dir: -1,
      },
    };

    const select: ITableSelectable = {
      enableSelect: true,
      afterChangeSelected: (m: IHitter | undefined) => {
        if (!m) {
          return;
        }

        this.props.hittersCx.openCrudDialog({
          key: Date.now(),
          action: CrudAction.Update,
          models: [m],
        });
      },
    };

    const checkable: ITableCheckable = {
      checkboxColumnIndex: 0,
      checkedActions: this.getCheckedMenuActions,
    };

    return (
      <ErrorBoundary componentName="HitterLibrary">
        <FlexTableWrapper
          gap={RADIX.FLEX.GAP.SECTION}
          header={
            <SectionHeader
              header={t('main.hitters')}
              actions={[
                {
                  color: RADIX.COLOR.SUCCESS,
                  suffixIcon: <PlusIcon />,
                  label: 'common.create',
                  disabled: this.props.hittersCx.loading,
                  onClick: () => {
                    this.props.hittersCx.openCrudDialog({
                      key: Date.now(),
                      action: CrudAction.Create,
                      models: [],
                    });
                  },
                },
              ]}
            />
          }
          table={
            <CommonTableHoC
              id="HitterLibrary"
              toolbarContent={<HitterToolbar />}
              displayColumns={this.BASE_COLUMNS}
              displayData={data}
              {...pagination}
              {...checkable}
              {...select}
              {...sort}
              vFlex
            />
          }
        />
      </ErrorBoundary>
    );
  }

  private getCheckedMenuActions(checked: IHitter[]): IMenuAction[] {
    return [
      {
        prefixIcon: <TrashIcon />,
        label: 'common.delete-selected',
        color: RADIX.COLOR.DANGER,
        onClick: () => {
          this.props.hittersCx.openCrudDialog({
            key: Date.now(),
            action: CrudAction.Delete,
            models: checked,
          });
        },
      },
    ];
  }
}
