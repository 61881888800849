import { ErrorBoundary } from 'components/common/error-boundary';
import { SpinView } from 'components/common/spin-view';
import { CommonTabs } from 'components/common/tabs';
import { TrajectoryView } from 'components/common/trajectory-view';
import { IInputModel } from 'components/common/trajectory-view/helpers';
import { IAuthContext } from 'contexts/auth.context';
import { ISeamOrientation, ISpin } from 'lib_ts/interfaces/pitches/i-base';
import { useState } from 'react';

enum TabKey {
  Spin = 'Spin',
  Trajectory = 'Trajectory',
}

interface IProps {
  // update this to restart the traj animation
  animateKey: number;

  authCx: IAuthContext;

  /** for spin tab */
  spin: ISpin;
  /** for spin tab */
  orientation: ISeamOrientation;
  /** for trajectory tab */
  trajModels: IInputModel[];
}

export const BallPreview = (props: IProps) => {
  const [activeTab, setActiveTab] = useState(TabKey.Spin);

  return (
    <ErrorBoundary componentName="BallPreview">
      <CommonTabs
        value={activeTab}
        onValueChange={(value) => setActiveTab(value as TabKey)}
        tabs={[
          {
            value: TabKey.Spin,
            label: 'common.spin',
            content: (
              <SpinView
                wx={props.spin.wx}
                wy={props.spin.wy}
                wz={props.spin.wz}
                latitude_deg={props.orientation.latitude_deg}
                longitude_deg={props.orientation.longitude_deg}
              />
            ),
          },
          {
            value: TabKey.Trajectory,
            label: 'common.trajectory',
            content: (
              <TrajectoryView
                animateKey={props.animateKey}
                models={props.trajModels}
                startingZoom={1.75}
              />
            ),
          },
        ]}
      />
    </ErrorBoundary>
  );
};
