import { Box, Card, Flex, Grid, Heading } from '@radix-ui/themes';
import { BreakCanvas } from 'classes/break-canvas';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { TrainingHelper } from 'classes/helpers/training-helper';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { MIN_CONFIDENCE, PlateCanvas } from 'classes/plate-canvas';
import { BreakView } from 'components/common/break-view';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { DetectionFailedCallout } from 'components/machine/detection-failed';
import { IPresetTrainingContext } from 'components/machine/dialogs/preset-training/context';
import { DataTable } from 'components/machine/dialogs/preset-training/controls/data-table';
import { PresetSelector } from 'components/machine/dialogs/preset-training/controls/preset-selector';
import { PTStatusBar } from 'components/machine/dialogs/preset-training/controls/status-bar';
import { PresetTrainingPlateView } from 'components/machine/dialogs/preset-training/plate';
import { PitchListState } from 'components/sections/pitch-list/store/pitch-list-store';
import { IAimingContext } from 'contexts/aiming.context';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { ITrainingContext, TrainingContext } from 'contexts/training.context';
import { PTStep, ProgressDirection } from 'enums/training.enums';
import { t } from 'i18next';
import { isAppearanceDark } from 'index';
import { FT_TO_INCHES, clamp } from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMSFromMSDict, getMergedMSDict } from 'lib_ts/classes/ms.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { FireOption } from 'lib_ts/enums/machine.enums';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { TrackingDevice } from 'lib_ts/enums/training.enums';
import { IBallStatusMsg } from 'lib_ts/interfaces/i-machine-msg';
import { IPrecisionTrainLogEventData } from 'lib_ts/interfaces/i-session-event';
import {
  IBallState,
  IClosedLoopBuildChars,
  ITrajectory,
  ITrajektRefBreak,
} from 'lib_ts/interfaces/pitches';
import { IPitch } from 'lib_ts/interfaces/pitches/i-pitch';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import React, { ReactNode } from 'react';
import { SessionEventsService } from 'services/session-events.service';
import { StateTransformService } from 'services/state-transform.service';

const COMPONENT_NAME = 'PresetTrainingControls';

// give time for users to see the complete badge before moving ahead
const AUTO_ADVANCE_DELAY_MS = 3_000;

const ROTATION_STEP_SIZE = 0.75;

const STEP_TIMEOUT_MS = 3_000;

export const SEEK_RESULT_STEPS = [PTStep.SeekSuccess, PTStep.SeekFailure];

const SEEK_IN_PROGRESS_STEPS = [
  PTStep.SeekBreaks,
  PTStep.SeekSpeed,
  PTStep.SeekSpins,
];

const getStepSize = (sampleSize: number) =>
  clamp(0.9 - Math.pow(0.5, sampleSize), 0.5, 0.9);

const FlexCard = (props: { header: ReactNode; children: ReactNode }) => (
  <Card style={{ height: '100%' }}>
    <Flex direction="column" style={{ height: '100%' }}>
      <Heading size={RADIX.HEADING.SIZE.MD}>{props.header}</Heading>
      <Flex flexGrow="1" align="center" justify="center">
        {props.children}
      </Flex>
    </Flex>
  </Card>
);

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  aimingCx: IAimingContext;
  trainingCx: ITrainingContext;
  presetCx: IPresetTrainingContext;

  pitches: Partial<IPitch>[];

  // callback when training of every pitch is complete
  onFinish?: () => void;
  updatePitches?: PitchListState['updatePitches'];
}

interface IState {
  /** true: overrides pretty much every other state, there is a fatal error (e.g. pitch does not have a msDict entry) */
  error: boolean;

  optimized: boolean;
  newShot?: IMachineShot;

  step: PTStep;

  /** for active/current pitch */
  matches: IMachineShot[];

  // while rebuilding a pitch via closed loop
  loading: boolean;

  // reset to 0 whenever changing pitches, tracks how many previous CL builds have been made
  iAdjustment: number;

  // for manipulating in memory without modifying original pitches until a good ms is found
  pitches: Partial<IPitch>[];
}

export class PresetTrainingControls extends React.Component<IProps, IState> {
  private init = false;
  private trainedTimeout: any;
  private sendTimeout: any;
  private stepTimeout: any;

  private plate_canvas = PlateCanvas.makeLandscape(isAppearanceDark());

  private plate?: PresetTrainingPlateView;
  private statusBar?: PTStatusBar;

  constructor(props: IProps) {
    super(props);

    const copiedPitches = props.pitches.map(
      (p) => ({ ...p }) as Partial<IPitch>
    );

    const firstPitch = copiedPitches[props.trainingCx.pitchIndex];

    this.state = {
      error: false,
      optimized: !this.props.presetCx.isPitchAdjustable(firstPitch),
      pitches: copiedPitches,
      matches: [],
      step: PTStep.Firing,
      loading: false,
      iAdjustment: 0,
    };

    this.afterTrainingMsgs = this.afterTrainingMsgs.bind(this);
    this.changePitch = this.changePitch.bind(this);
    this.handleBallStatus = this.handleBallStatus.bind(this);
    this.handlePitchTrained = this.handlePitchTrained.bind(this);
    this.initialize = this.initialize.bind(this);
    this.isLastPitch = this.isLastPitch.bind(this);

    this.renderBreaks = this.renderBreaks.bind(this);
    this.renderPlate = this.renderPlate.bind(this);
    this.renderStepDescription = this.renderStepDescription.bind(this);
    this.renderThresholdsWarning = this.renderThresholdsWarning.bind(this);

    this.updateMatchesAndActive = this.updateMatchesAndActive.bind(this);
  }

  componentDidMount() {
    if (!this.init) {
      this.init = true;
      this.initialize();
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (
      prevProps.trainingCx.lastUpdated !== this.props.trainingCx.lastUpdated
    ) {
      this.afterTrainingMsgs();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.trainedTimeout);
    clearTimeout(this.sendTimeout);
    clearTimeout(this.stepTimeout);
  }

  private async initialize() {
    if (this.state.pitches.length === 0) {
      return;
    }

    await this.props.aimingCx.setPitch(this.state.pitches[0] as IPitch, {
      loadShots: true,
      sendConfig: {
        training: true,
        skipPreview: true,
        trigger: `${COMPONENT_NAME} > mount`,
      },
    });
  }

  private handlePitchTrained() {
    clearTimeout(this.trainedTimeout);

    this.trainedTimeout = setTimeout(() => {
      if (this.isLastPitch()) {
        this.props.onFinish?.();
        return;
      }

      if (!this.props.machineCx.autoFire) {
        return;
      }

      this.changePitch(ProgressDirection.Next);
    }, AUTO_ADVANCE_DELAY_MS);
  }

  private async handleBallStatus(event: CustomEvent) {
    const data: IBallStatusMsg = event.detail;

    switch (data.ball_count) {
      case 1: {
        this.statusBar?.sendPitch('handleBallStatus');
        return;
      }

      default: {
        return;
      }
    }
  }

  private async afterTrainingMsgs() {
    const finalMsg = this.props.trainingCx.getFinalMsg();

    if (!finalMsg) {
      return;
    }

    if (finalMsg.success) {
      await this.updateMatchesAndActive('afterTrainingMsgs');
      return;
    }

    if (finalMsg.timeout) {
      const msg = (() => {
        switch (finalMsg.timeout.missing) {
          case 'fire': {
            return t('tr.training-timeout-no-fire-msg', {
              sec: finalMsg.timeout.delay_s,
            });
          }

          case 'shot': {
            return t('tr.training-timeout-no-shot-msg', {
              sec: finalMsg.timeout.delay_s,
            });
          }

          case 'MSBS':
          case TrackingDevice.RapsodoV3PRO:
          case TrackingDevice.TrackmanB1:
          case TrackingDevice.TrajektVision:
          default: {
            return t('tr.training-timeout-missing-msg', {
              sec: finalMsg.timeout.delay_s,
              missing: finalMsg.timeout.missing,
            });
          }
        }
      })();

      NotifyHelper.warning({
        message_md: msg,
        inbox: true,
        buttons: [
          {
            label: 'common.read-more',
            onClick: () =>
              window.open(t('common.intercom-url') + HELP_URLS.RAPSODO_ERRORS),
          },
        ],
      });
      await this.updateMatchesAndActive('afterTrainingMsgs');
      return;
    }

    // unknown/miscellaneous failure to train
    NotifyHelper.warning({
      message_md: finalMsg.message ?? 'Failed to train (unknown error).',
      inbox: true,
      buttons: [
        {
          label: 'common.read-more',
          onClick: () =>
            window.open(t('common.intercom-url') + HELP_URLS.RAPSODO_ERRORS),
        },
      ],
    });
    await this.updateMatchesAndActive('afterTrainingMsgs');
  }

  private async updateMatchesAndActive(source: string) {
    console.debug(`PT updating matches (${source})`);

    const { pitchIndex } = this.props.trainingCx;
    const { machine, fireOptions, addFireOption } = this.props.machineCx;

    // keep track of this so we know what to do after updating the state
    const prevStep = this.state.step;

    const pitch = this.state.pitches[pitchIndex];
    if (!pitch) {
      throw new Error(`Invalid pitch (not defined) at index ${pitchIndex}`);
    }

    if (!pitch._id) {
      throw new Error(`Invalid pitch (no _id) at index ${pitchIndex}`);
    }

    const nextState: Partial<IState> = {};

    const matchesBefore = this.state.matches.map((s) => s._id);

    await this.props.aimingCx.updateMatches(
      // ensure we pull enough shots to finish if possible
      Math.max(
        // e.g. we have shots but some are missing bs, so we need more shots
        matchesBefore.length + 1,
        this.props.presetCx.active.spec.sampleSize,
        machine.training_threshold
      )
    );

    // might be changed later if we need to rebuild via closed loop
    const aimed = this.props.aimingCx.getAimed({
      training: true,
      stepSize: ROTATION_STEP_SIZE,
    });

    if (!aimed) {
      console.error({
        event: `${COMPONENT_NAME}: failed to rotate pitch to middle`,
      });
      return;
    }

    if (!aimed.ms.matching_hash) {
      console.error(
        `${COMPONENT_NAME}: invalid matching hash after updating matches, cannot draw shots`
      );
      return;
    }

    const matchesAfter = aimed.usingShots;
    nextState.matches = matchesAfter;

    const logData: IPrecisionTrainLogEventData = {
      pitch_id: pitch._id,
      attempt: this.state.iAdjustment,
      shots: matchesAfter,
    };

    const newShot = matchesAfter.find((s) => !matchesBefore.includes(s._id));
    nextState.newShot = newShot;

    if (!newShot) {
      console.warn(
        `${COMPONENT_NAME}: failed to find a new shot while updating matches`
      );
    }

    const skippingValidation = fireOptions.includes(
      FireOption.SkipRapsodoValidation
    );

    const showConfidenceWarning =
      // admins always see low confidence warnings
      this.props.authCx.current.role === UserRole.admin ||
      // non-admins only see low confidence warnings while not skipping rapsodo validation
      !skippingValidation;

    if (
      showConfidenceWarning &&
      newShot?.confidence &&
      !TrainingHelper.isConfidenceSufficient({
        device: machine.tracking_device,
        confidence: newShot.confidence,
        minValue: MIN_CONFIDENCE,
      })
    ) {
      const route = newShot.rapsodo_shot_id
        ? HELP_URLS.RAPSODO_ERRORS
        : newShot.trackman_pitch_id
        ? HELP_URLS.TRACKMAN_ERRORS
        : undefined;

      NotifyHelper.warning({
        message_md: `Low confidence detected.`,
        buttons: [
          {
            label: 'common.read-more',
            invisible: !route,
            onClick: () => window.open(t('common.intercom-url') + route),
          },
          {
            label: 'common.skip-validation',
            // avoid showing if you're already skipping (e.g. admin always sees toast)
            invisible: skippingValidation,
            dismissAfterClick: true,
            onClick: () => addFireOption(FireOption.SkipRapsodoValidation),
          },
        ],
      });
    }

    const requireConfidence =
      // TM and TV do not provide spin => no spin confidence should not block completion
      ![TrackingDevice.TrackmanB1, TrackingDevice.TrajektVision].includes(
        machine.tracking_device
      ) && !fireOptions.includes(FireOption.SkipRapsodoValidation);

    const SUMMARY_FN = MiscHelper.getMedianObject;

    const nextStep = !this.state.optimized
      ? this.plate_canvas.preOptimizeStep({
          prevStep: prevStep,
          spec: this.props.presetCx.active.spec,
          iAdjustment: this.state.iAdjustment,
          summaryFn: SUMMARY_FN,
          pitch: pitch,
          shot: newShot,
          allShots: matchesAfter,
        })
      : this.plate_canvas.postOptimizeStep({
          device: machine.tracking_device,
          prevStep: prevStep,
          pitch: pitch,
          shot: newShot,
          allShots: matchesAfter,
          threshold: this.props.presetCx.active.spec.sampleSize,
          requireConfidence: requireConfidence,
        });

    // is undefined unless seek is done (as failure or success)
    const nextOptimizedStep = SEEK_RESULT_STEPS.includes(nextStep)
      ? this.plate_canvas.postOptimizeStep({
          device: machine.tracking_device,
          prevStep: prevStep,
          pitch: pitch,
          shot: newShot,
          allShots: matchesAfter,
          threshold: machine.training_threshold,
          requireConfidence: requireConfidence,
        })
      : undefined;

    nextState.step = nextStep;
    logData.step = nextStep;

    if (newShot) {
      // every shot gets a training_complete flag, but the value is only true when complete
      newShot.training_complete = [nextStep, nextOptimizedStep].includes(
        PTStep.Complete
      );

      await this.props.matchingCx.updateShot({
        matching_hash: aimed.ms.matching_hash,
        shot_id: newShot._id,
        payload: { training_complete: newShot.training_complete },
      });
    }

    if (SEEK_IN_PROGRESS_STEPS.includes(nextStep)) {
      // increment the attempt counter on CL step
      nextState.iAdjustment = this.state.iAdjustment + 1;
    }

    // we found the ms, update the pitch
    if (SEEK_RESULT_STEPS.includes(nextStep)) {
      nextState.optimized = true;

      const ms = getMSFromMSDict(pitch, machine).ms;

      if (ms) {
        if (nextStep === PTStep.SeekSuccess) {
          ms.precision_trained = this.props.presetCx.active.precisionTrained;
        }

        if (this.props.updatePitches) {
          await this.props.updatePitches({
            payloads: [
              {
                _id: pitch._id,
                msDict: pitch.msDict,
              },
            ],
          });
        }
      }
    }

    if ([PTStep.Sending, ...SEEK_RESULT_STEPS].includes(nextStep)) {
      // do nothing, just proceed
    } else if (newShot && SEEK_IN_PROGRESS_STEPS.includes(nextStep)) {
      // run an iteration of the closed loop logic, if we haven't run out of attempts
      const target_bs = pitch.bs;
      if (!target_bs) {
        NotifyHelper.error({
          message_md: 'Cannot proceed without a target ball state.',
        });
        return;
      }

      const target_traj = pitch.traj;
      if (!target_traj) {
        NotifyHelper.error({
          message_md: 'Cannot proceed without a target trajectory.',
        });
        return;
      }

      const originalMS = getMSFromMSDict(pitch, machine).ms;

      if (!originalMS) {
        NotifyHelper.error({
          message_md:
            'Cannot proceed without original machine state from pitch.',
        });
        return;
      }

      const priority = pitch.priority ?? BuildPriority.Spins;

      const shotsWithBS = matchesAfter.filter((s) => s.bs);

      const summary_bs =
        shotsWithBS.length > 0
          ? (SUMMARY_FN(
              shotsWithBS.map((s) => s.bs as IBallState)
            ) as IBallState)
          : undefined;

      if (priority === BuildPriority.Spins) {
        if (!summary_bs) {
          NotifyHelper.error({
            message_md: 'Cannot proceed without a summary ball state.',
          });
          return;
        }
      }

      const spinPrioChars = () => {
        return {
          machineID: machine.machineID,
          mongo_id: pitch._id,
          use_gradient: this.props.presetCx.gradient,
          ms: originalMS,
          traj: target_traj,
          target_bs: target_bs,
          actual_bs: summary_bs ?? target_bs,
          priority: priority,
        };
      };

      const summary_break =
        matchesAfter.length > 0
          ? (SUMMARY_FN(
              matchesAfter.map((s) => TrajHelper.getBreaksFromShot(s))
            ) as ITrajektRefBreak)
          : undefined;

      const shotsWithTraj = matchesAfter.filter((s) => s.traj);
      const summary_traj =
        shotsWithTraj.length > 0
          ? (SUMMARY_FN(
              matchesAfter
                .filter((s) => s.traj)
                .map((s) => s.traj as ITrajectory)
            ) as ITrajectory)
          : undefined;

      const pitchBreaks = PitchListHelper.getSafePitchBreaks(pitch);

      if (priority === BuildPriority.Breaks) {
        if (!pitchBreaks) {
          NotifyHelper.error({
            message_md: 'Cannot proceed without target breaks from pitch.',
          });
          return;
        }

        if (!summary_break) {
          NotifyHelper.error({
            message_md: 'Cannot proceed without a summary break.',
          });
          return;
        }

        if (!summary_traj) {
          NotifyHelper.error({
            message_md: 'Cannot proceed without a summary traj.',
          });
          return;
        }
      }

      const breakPrioChars = () => {
        return {
          machineID: machine.machineID,
          mongo_id: pitch._id,
          use_gradient: this.props.presetCx.gradient,
          ms: originalMS,
          traj: summary_traj ?? target_traj,
          target_bs: target_bs,
          // actual_bs can fall back to target_bs in break priority, but a BS does need to be provided here.
          // TODO: handle this in Python, and made the actual_bs optional.
          actual_bs: summary_bs ?? target_bs,
          actual_mvmt: summary_break
            ? {
                break_x_ft: summary_break.xInches / FT_TO_INCHES,
                break_z_ft: summary_break.zInches / FT_TO_INCHES,
              }
            : undefined,
          target_mvmt: pitchBreaks
            ? {
                break_x_ft: pitchBreaks.xInches / FT_TO_INCHES,
                break_z_ft: pitchBreaks.zInches / FT_TO_INCHES,
              }
            : undefined,
          priority: priority,
        };
      };

      const chars: IClosedLoopBuildChars =
        priority === BuildPriority.Breaks ? breakPrioChars() : spinPrioChars();

      logData.chars = chars;

      NotifyHelper.success({
        message_md: `Attempting optimization of pitch ${pitchIndex + 1}...`,
        delay_ms: 3_000,
      });

      const result = (
        await StateTransformService.getInstance().buildClosedLoop({
          machineID: machine.machineID,
          pitches: [chars],
          stepSize: getStepSize(this.props.presetCx.active.spec.sampleSize),
          notifyError: false,
        })
      ).find((p) => p.mongo_id === pitch._id);

      if (!result) {
        // yuck but let's get it working
        const keyword =
          pitch.priority === BuildPriority.Breaks ? 'break' : 'spin';

        const msg = `Your current model does not support ${keyword} data. Precision training for this pitch will be available if you update your model to support ${keyword}.`;

        NotifyHelper.warning({
          message_md: msg,
        });

        this.setState(
          {
            step: PTStep.SeekFailure,
            optimized: true,
          },
          () => this.updateMatchesAndActive('failed to build')
        );
        return;
      }

      // update the ms
      pitch.msDict = getMergedMSDict(machine, [result.ms], pitch.msDict);

      // just in case editing the pitch in place doesn't actually "save" the changes for the next iteration
      const nextPitches = [...this.state.pitches];
      nextPitches[pitchIndex] = { ...pitch };
      nextState.pitches = nextPitches;

      // assumption is there can't be matches for this yet since it's a brand new ms
      nextState.matches = [];

      await this.props.aimingCx.setPitch(pitch as IPitch, {
        loadShots: true,
      });
    }

    this.setState(nextState as any, async () => {
      if (
        nextState.step &&
        [...SEEK_IN_PROGRESS_STEPS, ...SEEK_RESULT_STEPS].includes(
          nextState.step
        )
      ) {
        SessionEventsService.postEvent({
          category: 'pitch',
          tags: 'precision train',
          data: logData,
        });
      }

      // always resend since shots for aiming will have changed
      await this.statusBar?.sendPitch(`after setState (${nextStep})`);

      this.plate?.drawShots(`${COMPONENT_NAME} > updated matches`);

      switch (nextStep) {
        case PTStep.Sending: {
          // timeout should prevent auto-fire from prematurely firing
          clearTimeout(this.sendTimeout);

          this.sendTimeout = setTimeout(() => {
            this.setState({ step: PTStep.Firing });
          }, 500);
          break;
        }

        case PTStep.Complete: {
          this.handlePitchTrained();
          break;
        }

        case PTStep.SeekBreaks:
        case PTStep.SeekSpeed:
        case PTStep.SeekSpins: {
          // longer timeout should prevent auto-fire from prematurely firing
          clearTimeout(this.stepTimeout);

          this.stepTimeout = setTimeout(() => {
            this.setState({ step: PTStep.Firing });
          }, STEP_TIMEOUT_MS);
          break;
        }

        case PTStep.SeekFailure:
        case PTStep.SeekSuccess: {
          // longer timeout should prevent auto-fire from prematurely firing
          clearTimeout(this.stepTimeout);

          const isComplete = PTStep.Complete === nextOptimizedStep;

          this.stepTimeout = setTimeout(() => {
            this.setState(
              {
                step: isComplete ? PTStep.Complete : PTStep.Firing,
              },
              () => {
                if (isComplete) {
                  this.handlePitchTrained();
                }
              }
            );
          }, STEP_TIMEOUT_MS);
          break;
        }

        default: {
          break;
        }
      }
    });
  }

  /** can be used by parent components (e.g. model builder) to skip a pitch or return to a previous pitch */
  async changePitch(delta: ProgressDirection) {
    // In case the user changes pitches before handlePitchTrained can fire the handler
    clearTimeout(this.trainedTimeout);

    this.statusBar?.fireButton?.resetR2FWait(`${COMPONENT_NAME} > changePitch`);

    const nextIndex = this.props.trainingCx.pitchIndex + delta;

    if (nextIndex < 0 || nextIndex >= this.state.pitches.length) {
      // avoid changing to illegal indices
      return;
    }

    const nextPitch = this.state.pitches[nextIndex] as IPitch;

    await this.props.aimingCx.setPitch(nextPitch, {
      loadShots: true,
    });

    this.props.trainingCx.setPitchIndex(nextIndex);

    this.setState(
      {
        step: PTStep.Waiting,
        // if the pitch can't/won't be adjusted, treat it as if already optimized so it does not rebuild
        optimized: !this.props.presetCx.isPitchAdjustable(nextPitch),
        matches: [],
        iAdjustment: 0,
      },
      () => {
        this.updateMatchesAndActive('changePitch');
      }
    );
  }

  private renderStepDescription() {
    const content = (() => {
      switch (this.state.step) {
        case PTStep.Sending: {
          return <p>{t('tr.wait-for-r2f-msg')}</p>;
        }

        case PTStep.Waiting: {
          return <p>{t('tr.wait-for-data-msg')}</p>;
        }

        case PTStep.Firing: {
          return (
            <p>
              {this.props.machineCx.autoFire
                ? t('tr.auto-fire-when-ready-msg')
                : t('tr.fire-when-ready-msg')}
            </p>
          );
        }

        case PTStep.SeekBreaks: {
          return <p>{t('tr.seek-breaks-msg')}</p>;
        }

        case PTStep.SeekSpins: {
          return <p>{t('tr.seek-spins-msg')}</p>;
        }

        case PTStep.SeekSpeed: {
          return <p>{t('tr.seek-speed-msg')}</p>;
        }

        case PTStep.SeekSuccess: {
          if (this.state.iAdjustment === 0) {
            return <p>{t('tr.seek-success-msg')}</p>;
          }

          return (
            <p>
              {t('tr.seek-success-after-x-msg', {
                x: this.state.iAdjustment + 1,
              })}
            </p>
          );
        }

        case PTStep.SeekFailure: {
          return (
            <p>
              {t('tr.seek-failure-after-x-msg', {
                x: this.state.iAdjustment + 1,
              })}
            </p>
          );
        }

        case PTStep.Complete: {
          return (
            <>
              <p>{t('tr.pitch-trained-msg')}</p>
              <p>
                {this.props.trainingCx.pitchIndex ===
                this.state.pitches.length - 1
                  ? t('tr.complete-msg')
                  : t('tr.click-next-msg')}
              </p>
            </>
          );
        }

        default: {
          return;
        }
      }
    })();

    // provide min-height to avoid moving up and down when content disappears
    return (
      <div className="align-center" style={{ minHeight: '50px' }}>
        {content}
      </div>
    );
  }

  private isLastPitch() {
    return this.props.trainingCx.pitchIndex === this.state.pitches.length - 1;
  }

  private renderThresholdsWarning() {
    const warning = (
      <CommonCallout text="The thresholds you have entered might be difficult or impossible to reproduce " />
    );

    if (this.props.presetCx.active.spec.deltaSpeedMPH < 0.5) {
      return warning;
    }

    if (this.props.presetCx.active.spec.deltaBreaksInches < 3) {
      return warning;
    }

    if (this.props.presetCx.active.spec.deltaSpinsRPM < 250) {
      return warning;
    }
  }

  render() {
    const pitch = this.props.aimingCx.pitch;
    const showBreaks = pitch?.priority === BuildPriority.Breaks;

    const warning = this.renderThresholdsWarning();

    const colSpan = [
      // first column
      3,
      // second column
      showBreaks ? 3 : 6,
      // optional third column
      showBreaks ? 3 : 0,
    ];

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
          <Grid
            data-identifier="PTControls"
            columns="9"
            gap={RADIX.FLEX.GAP.SM}
            align="stretch"
          >
            {/* column 1 */}
            <Box gridColumn={`span ${colSpan[0]}`}>
              <PresetSelector />
            </Box>

            {/* column 2 */}
            <Box gridColumn={`span ${colSpan[1]}`}>{this.renderPlate()}</Box>

            {/* column 3 - only for breaks */}
            {showBreaks && (
              <Box gridColumn={`span ${colSpan[2]}`}>{this.renderBreaks()}</Box>
            )}

            {/* column 1 - warning about thresholds */}
            {!!warning && (
              <Box gridColumn={`span ${colSpan[0]}`}>{warning}</Box>
            )}

            {/* column 2-3 - warnings and status bar */}
            <Box
              gridColumn={`span ${
                (warning ? 0 : colSpan[0]) + colSpan[1] + colSpan[2]
              }`}
            >
              <TrainingContext.Consumer>
                {(trainingCx) => {
                  if (trainingCx.showFailure) {
                    return (
                      <DetectionFailedCallout
                        onOpenSettings={this.props.onFinish}
                      />
                    );
                  }

                  return (
                    <Card style={{ height: '100%' }}>
                      <PTStatusBar
                        ref={(elem) => (this.statusBar = elem as PTStatusBar)}
                        cookiesCx={this.props.cookiesCx}
                        authCx={this.props.authCx}
                        machineCx={this.props.machineCx}
                        matchingCx={this.props.matchingCx}
                        aimingCx={this.props.aimingCx}
                        trainingCx={this.props.trainingCx}
                        iteration={this.state.iAdjustment + 1}
                        step={this.state.step}
                        shot={
                          pitch
                            ? this.props.matchingCx.safeGetShotsByPitch(pitch)
                                .length
                            : 0
                        }
                        totalShots={this.props.presetCx.active.spec.sampleSize}
                        isLastPitch={this.isLastPitch()}
                        trained={this.state.step === PTStep.Complete}
                        changePitch={this.changePitch}
                        onFinish={this.props.onFinish}
                        beforeFire={(_, isReady) => {
                          if (isReady) {
                            this.setState({ step: PTStep.Waiting });
                          }
                        }}
                      />
                    </Card>
                  );
                }}
              </TrainingContext.Consumer>
            </Box>
          </Grid>

          {pitch && (
            <DataTable
              data-identifier="PTDataTable"
              machineCx={this.props.machineCx}
              sampleSize={this.props.presetCx.active.spec.sampleSize}
              priority={pitch.priority ?? BuildPriority.Spins}
              targetBS={pitch.bs}
              targetTraj={pitch.traj}
              targetBreaks={pitch.breaks ?? TrajHelper.getBreaks(pitch.traj)}
              actualShots={this.state.matches}
              afterArchive={(id) => {
                this.setState(
                  {
                    // remove the archived shot from matches
                    matches: this.state.matches.filter((s) => s._id !== id),
                  },
                  () => {
                    // update the rest of the UI given the change
                    this.updateMatchesAndActive('archive shot via data table');
                  }
                );
              }}
            />
          )}
        </Flex>
      </ErrorBoundary>
    );
  }

  // draw a slimmer version when breaks are also shown
  private renderPlate() {
    const pitch = this.props.aimingCx.pitch;

    return (
      <FlexCard header={t('tr.strike-zone')}>
        <div
          style={{
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <PresetTrainingPlateView
            ref={(elem) => (this.plate = elem as PresetTrainingPlateView)}
            sliderStyle={{
              aspectRatio: '1.6',
              width: 'auto',
              // ensures this is centered horizontally
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: '50%',
              transform: 'translate(-50%, 0)',
            }}
            sizerStyle={{
              aspectRatio: '1.6',
              position: 'absolute',
              height: '100%',
              width: '100%',
            }}
            cookiesCx={this.props.cookiesCx}
            machineCx={this.props.machineCx}
            defaultX={this.props.aimingCx.plate.plate_x}
            defaultY={this.props.aimingCx.plate.plate_z}
            step={this.state.step}
            centeredPitch={pitch as IPitch}
            newShotID={this.state.newShot?._id}
            shots={this.state.matches}
            // when user confirms inputs and a shot gets updated (e.g. with user_traj)
            onUpdateShot={async (shot) => {
              if (!shot) {
                // continued without setting override for shot (e.g. timeout without manual input)
                this.setState({ step: PTStep.Firing });
                return;
              }

              this.updateMatchesAndActive('TrainingPlateView > onUpdateShot');
            }}
            disabled
            simple
          />
        </div>
      </FlexCard>
    );
  }

  private renderBreaks() {
    const pitch = this.props.aimingCx.pitch;

    const actual = pitch
      ? BreakCanvas.getMedian(
          this.props.matchingCx
            .safeGetShotsByPitch(pitch)
            .map((s) => TrajHelper.getBreaksFromShot(s))
        )
      : undefined;

    const actual_x_in = actual ? actual.xInches : 0;
    const actual_z_in = actual ? actual.zInches : 0;

    const target = pitch
      ? pitch.breaks ?? TrajHelper.getBreaks(pitch.traj)
      : undefined;

    const target_x_in = target ? -target.xInches : actual_x_in;
    const target_z_in = target ? target.zInches : actual_z_in;

    return (
      <FlexCard header="Break Plot">
        <div
          style={{
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <BreakView
            // forces redraw for new pitch => target moves
            key={`breaks-${pitch?._id ?? 'no-id'}`}
            sliderStyle={{
              aspectRatio: '1',
              width: 'auto',
              // ensures this is centered horizontally
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: '50%',
              transform: 'translate(-50%, 0)',
            }}
            sizerStyle={{
              aspectRatio: '1',
              height: '100%',
              width: '100%',
            }}
            actual_x_in={actual_x_in}
            actual_z_in={actual_z_in}
            target_x_in={target_x_in}
            target_z_in={target_z_in}
            shots={this.state.matches}
            newShotID={this.state.newShot?._id}
            disabled
            training
          />
        </div>
      </FlexCard>
    );
  }
}
