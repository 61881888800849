export enum SettingsSection {
  Machine = 'Machine',
  Interface = 'Interface',
  User = 'User',
  Accessories = 'Accessories',
}

interface ISectionDef {
  label: string;
  value: SettingsSection;
}

export const ALL_SECTIONS: ISectionDef[] = [
  {
    label: 'common.machine',
    value: SettingsSection.Machine,
  },
  {
    label: 'settings.interface',
    value: SettingsSection.Interface,
  },
  {
    label: 'settings.user',
    value: SettingsSection.User,
  },
  {
    label: 'settings.accessories',
    value: SettingsSection.Accessories,
  },
];

export enum MachineSettingsTabKey {
  General = 'General',
  Projector = 'Projector',
  Firing = 'Firing',
  TrackingDevice = 'Tracking Device',
  Maintenance = 'Maintenance',
  Switch = 'Switch Machine',
}

export enum UserSettingsTabKey {
  ReassignPitchLists = 'Reassign Pitch Lists',
  TeamAdmins = 'Team Admins',
}
