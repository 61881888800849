import {
  InterfaceSection,
  MachineSection,
  RemoteControlSection,
  UserSection,
} from 'components/common/settings-dialog/main-sections';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { MachineContext } from 'contexts/machine.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { SectionsContext } from 'contexts/sections.context';
import {
  MachineSettingsTabKey,
  SettingsSection,
  UserSettingsTabKey,
} from 'enums/settings';
import { useContext } from 'react';

interface IProps {
  active: SettingsSection;
  initialMachineTab?: MachineSettingsTabKey;
  initialUserTab?: UserSettingsTabKey;
  onClose: () => void;
}

export const SectionContent = (props: IProps) => {
  const authCx = useContext(AuthContext);
  const cookiesCx = useContext(CookiesContext);
  const machineCx = useContext(MachineContext);
  const listsCx = useContext(PitchListsContext);
  const sectionsCx = useContext(SectionsContext);

  switch (props.active) {
    case SettingsSection.Machine: {
      return (
        <MachineSection
          cookiesCx={cookiesCx}
          authCx={authCx}
          sectionsCx={sectionsCx}
          machineCx={machineCx}
          initialTab={props.initialMachineTab}
          onClose={props.onClose}
        />
      );
    }

    case SettingsSection.Interface: {
      return <InterfaceSection />;
    }

    case SettingsSection.User: {
      return (
        <UserSection
          authCx={authCx}
          listsCx={listsCx}
          onClose={props.onClose}
          initialTab={props.initialUserTab}
        />
      );
    }

    case SettingsSection.Accessories: {
      return (
        <RemoteControlSection cookiesCx={cookiesCx} machineCx={machineCx} />
      );
    }

    default: {
      return <></>;
    }
  }
};
