import { Flex, Text } from '@radix-ui/themes';
import { ProjectorSetupDialog } from 'components/common/dialogs/projector-setup';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSwitchInput } from 'components/common/form/switch';
import { SettingButton } from 'components/common/settings-dialog/button';
import { SettingForm } from 'components/common/settings-dialog/form';
import { InactiveCallout } from 'components/common/settings-dialog/inactive-callout';
import { SettingRow } from 'components/common/settings-dialog/row';
import { IMachineContext } from 'contexts/machine.context';
import { t } from 'i18next';
import { SfxName } from 'lib_ts/enums/machine-msg.enum';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import React from 'react';
import ReactMarkdown from 'react-markdown';

const COMPONENT_NAME = 'MachineProjectorTab';

const ENABLE_TOGGLE_HITTER_DETAILS = false;

interface IProps {
  machineCx: IMachineContext;
  onSave: (payload: Partial<IMachine>) => void;
}

interface IState {
  dialogProjector?: number;
}

export class MachineProjectorTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.renderDialog = this.renderDialog.bind(this);
  }

  componentDidMount(): void {
    this.props.machineCx.requestOverlayIDs(COMPONENT_NAME);
  }

  private renderDialog() {
    if (!this.state.dialogProjector) {
      return;
    }

    if (!this.props.machineCx.checkActive(true)) {
      return;
    }

    return (
      <ProjectorSetupDialog
        key={this.state.dialogProjector}
        machineCx={this.props.machineCx}
        onClose={() => this.setState({ dialogProjector: undefined })}
      />
    );
  }

  render() {
    const active = this.props.machineCx.checkActive(true);
    /** if the context is loading OR if you are not the active user on the machine */
    const disabled = this.props.machineCx.loading || !active;

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <SettingForm>
          {!active && <InactiveCallout />}

          <SettingRow
            header="settings.projector-setup"
            description={<Text>{t('settings.projector-setup-msg')}</Text>}
            input={
              <SettingButton
                label="common.projector-setup"
                disabled={!active}
                onClick={() =>
                  this.setState({
                    dialogProjector: Date.now(),
                  })
                }
              />
            }
          />
          <SettingRow
            separatorBefore
            header="settings.projector-audio"
            description={<Text>{t('settings.projector-audio-msg')}</Text>}
            input={
              <SettingButton
                label="settings.play-chime"
                disabled={!active}
                onClick={() => {
                  this.props.machineCx.playSound(SfxName.START);
                }}
              />
            }
          />
          <SettingRow
            separatorBefore
            header="settings.preview-next-pitch"
            description={<Text>{t('settings.preview-next-pitch-msg')}</Text>}
            input={
              <Flex justify="end">
                <CommonSwitchInput
                  id="projector-preview"
                  disabled={disabled}
                  defaultChecked={
                    this.props.machineCx.machine.enable_pitch_preview
                  }
                  onCheckedChange={(v) =>
                    this.props.onSave({
                      _id: this.props.machineCx.machine._id,
                      enable_pitch_preview: v,
                    })
                  }
                />
              </Flex>
            }
          />
          <SettingRow
            separatorBefore
            header="settings.recap-strike-zone"
            description={<Text>{t('settings.recap-strike-zone-msg')}</Text>}
            input={
              <Flex justify="end">
                <CommonSwitchInput
                  id="projector-recap"
                  disabled={disabled}
                  defaultChecked={
                    this.props.machineCx.machine.enable_pitch_recap
                  }
                  onCheckedChange={(e) =>
                    this.props.onSave({
                      _id: this.props.machineCx.machine._id,
                      enable_pitch_recap: e,
                    })
                  }
                />
              </Flex>
            }
          />
          <SettingRow
            separatorBefore
            header="settings.recap-hit-stats"
            description={
              <Text>
                <ReactMarkdown
                  children={t('settings.recap-hit-stats-msg').toString()}
                />
              </Text>
            }
            input={
              <Flex justify="end">
                <CommonSwitchInput
                  id="projector-hit-stats"
                  disabled={disabled}
                  defaultChecked={this.props.machineCx.machine.enable_hit_recap}
                  onCheckedChange={(e) =>
                    this.props.onSave({
                      _id: this.props.machineCx.machine._id,
                      enable_hit_recap: e,
                    })
                  }
                />
              </Flex>
            }
          />

          {ENABLE_TOGGLE_HITTER_DETAILS && (
            <SettingRow
              separatorBefore
              header="settings.display-hitter-details"
              description={
                <Text>{t('settings.display-hitter-details-msg')}</Text>
              }
              input={
                <Flex justify="end">
                  <CommonSwitchInput
                    id="projector-hitter-details"
                    disabled={disabled}
                    defaultChecked={
                      this.props.machineCx.machine.enable_hitter_details
                    }
                    onCheckedChange={(e) =>
                      this.props.onSave({
                        _id: this.props.machineCx.machine._id,
                        enable_hitter_details: e,
                      })
                    }
                  />
                </Flex>
              }
            />
          )}
        </SettingForm>

        {this.renderDialog()}
      </ErrorBoundary>
    );
  }
}
